/*------------------------------------------------------------------
Table of content
-------------------
1. Start Header Area
2. slider area start
3. features area css start
4. hot deals area css start
5. product
6. categories area
7. testimonial
8. blog
9. shop grid view
10. shop list view
11. product details
12. checkout
13. cart
14. wishlist
15. my account
16. compare
17. login register
18. about us
19. contact us
20. banner
21. modal
22. sidebar
23. footer
-------------------------------------------------------------------*/
/*------ Typography Style Start ------*/
body {
    font-size: 14px;
    line-height: 1.8;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
    color: #555;
}

a {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

a:hover,
a:focus {
    color: #24babc;
    outline: none;
    text-decoration: none;
}

p {
    margin-bottom: 0;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    color: #252525;
    margin-bottom: 0;
}

ul {
    margin: 0;
    padding: 0;
}

ul li {
    list-style: none;
}

strong,
b {
    font-weight: 700;
}

figure {
    margin: 0;
}

img {
    max-width: 100%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.bg-img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.btn,
button {
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: transparent;
}

.btn:active,
.btn:focus,
button:active,
button:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

input:focus,
textarea:focus {
    outline: none;
}

fieldset {
    margin-top: 30px;
}

fieldset legend {
    border-bottom: 1px solid #e7e7e7;
    color: #000;
    font-size: 24px;
    padding-bottom: 10px;
    text-transform: capitalize;
}

::-moz-selection {
    color: #fff;
    background: #91b2c3;
}

::selection {
    color: #fff;
    background: #91b2c3;
}

::-webkit-input-placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

::-moz-placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

::-ms-input-placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

::placeholder {
    color: #999;
    font-size: 13px;
    opacity: 1;
}

.modal-dialog {
    max-width: 1000px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .modal-dialog {
        max-width: 800px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .modal-dialog {
        max-width: 700px;
    }
}

.modal {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -99;
}

.modal.show {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    z-index: 99999999;
    -webkit-animation: slideInDown 0.4s forwards;
    animation: slideInDown 0.4s forwards;
}

.modal .modal-header {
    padding: 0 10px;
    border-bottom: none;
}

.modal .modal-header .close {
    color: #252525;
    font-size: 34px;
    opacity: 1;
    display: block;
    position: absolute;
    padding: 0;
    width: 30px;
    height: 30px;
    margin: 0;
    right: 10px;
    top: 10px;
    font-weight: 500;
    z-index: 999;
    line-height: 30px;
    border-radius: 50%;
}

.modal .modal-body {
    padding: 20px 20px 20px 20px;
}

@media only screen and (max-width: 479.98px) {
    .modal .modal-body {
        padding: 10px;
    }
}

/* ----tab problem fix css ----*/
.tab-content .tab-pane {
    display: block;
    height: 0;
    max-width: 100%;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
}

.tab-content .tab-pane.active {
    height: auto;
    opacity: 1;
    overflow: visible;
    visibility: visible;
}

/*------ custom tooltip start -------*/
.tooltip.show {
    opacity: 1;
}

.tooltip>.tooltip-inner {
    font-size: 13px;
    color: #fff;
    background-color: #24babc;
}

.tooltip.bs-tooltip-top .arrow:before {
    border-top-color: #24babc;
}

.tooltip.bs-tooltip-left .arrow:before {
    border-left-color: #24babc;
}

/*------ custom tooltip end -------*/
/* ---- Sick Slider arrow style start ----*/
.slick-slider .slick-slide>div>div {
    vertical-align: middle;
}

.slick-arrow-style button.slick-arrow {
    top: 50%;
    left: 0;
    height: 40px;
    width: 40px;
    color: #252525;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    display: block;
    position: absolute;
    -webkit-transform: translateY(-50%) scale(0.7);
    -ms-transform: translateY(-50%) scale(0.7);
    transform: translateY(-50%) scale(0.7);
    line-height: 40px;
    border-radius: 3px;
    z-index: 9;
    background-color: rgba(240, 240, 240, 0.7);
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.slick-arrow-style button.slick-arrow.slick-next {
    left: auto;
    right: 0;
    padding-left: 2px;
}

.slick-arrow-style button.slick-arrow:hover {
    color: #fff;
    background-color: #24babc;
}

.slick-arrow-style:hover button.slick-arrow {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(-50%) scale(1);
    -ms-transform: translateY(-50%) scale(1);
    transform: translateY(-50%) scale(1);
}

.slick-append button {
    height: 34px;
    width: 34px;
    line-height: 36px;
    position: inherit;
    display: inline-block;
    border-radius: 3px;
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
    background-color: rgba(240, 240, 240, 0.7);
}

.slick-append button.slick-next {
    margin-left: 5px;
}

.slick-append button:hover {
    color: #fff;
    background-color: #24babc;
}

.slick-append-style_2 {
    line-height: 1;
    -webkit-transform: translateY(5px);
    -ms-transform: translateY(5px);
    transform: translateY(5px);
}

.slick-append-style_2 button {
    font-size: 26px;
    height: auto;
    width: auto;
    line-height: 1;
}

.slick-append-style_2 button.slick-next {
    margin-left: 10px;
}

.slick-append-style_2 button:hover {
    color: #24babc;
    background-color: transparent;
}

/*--------- slick slider dot style start -------*/
.slick-dot-style ul.slick-dots {
    bottom: 20px;
    left: 50%;
    position: absolute;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.slick-dot-style ul.slick-dots li {
    display: inline-block;
    margin-right: 5px;
}

.slick-dot-style ul.slick-dots li button {
    width: 15px;
    height: 15px;
    padding: 0;
    border: none;
    display: block;
    text-indent: -5000px;
    cursor: pointer;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #e7e7e7;
}

.slick-dot-style ul.slick-dots li.slick-active button {
    background-color: #24babc;
}

.slick-row-3 .slick-list {
    margin: 0 -3px;
}

.slick-row-3 .slick-list .slick-slide {
    margin: 0 3px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-3 .slick-list {
        margin: 0 -3px !important;
    }

    .slick-sm-row-3 .slick-list .slick-slide {
        margin: 0 3px !important;
    }
}

.slick-row-4 .slick-list {
    margin: 0 -4px;
}

.slick-row-4 .slick-list .slick-slide {
    margin: 0 4px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-4 .slick-list {
        margin: 0 -4px !important;
    }

    .slick-sm-row-4 .slick-list .slick-slide {
        margin: 0 4px !important;
    }
}

.slick-row-5 .slick-list {
    margin: 0 -5px;
}

.slick-row-5 .slick-list .slick-slide {
    margin: 0 5px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-5 .slick-list {
        margin: 0 -5px !important;
    }

    .slick-sm-row-5 .slick-list .slick-slide {
        margin: 0 5px !important;
    }
}

.slick-row-6 .slick-list {
    margin: 0 -6px;
}

.slick-row-6 .slick-list .slick-slide {
    margin: 0 6px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-6 .slick-list {
        margin: 0 -6px !important;
    }

    .slick-sm-row-6 .slick-list .slick-slide {
        margin: 0 6px !important;
    }
}

.slick-row-7 .slick-list {
    margin: 0 -7px;
}

.slick-row-7 .slick-list .slick-slide {
    margin: 0 7px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-7 .slick-list {
        margin: 0 -7px !important;
    }

    .slick-sm-row-7 .slick-list .slick-slide {
        margin: 0 7px !important;
    }
}

.slick-row-8 .slick-list {
    margin: 0 -8px;
}

.slick-row-8 .slick-list .slick-slide {
    margin: 0 8px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-8 .slick-list {
        margin: 0 -8px !important;
    }

    .slick-sm-row-8 .slick-list .slick-slide {
        margin: 0 8px !important;
    }
}

.slick-row-9 .slick-list {
    margin: 0 -9px;
}

.slick-row-9 .slick-list .slick-slide {
    margin: 0 9px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-9 .slick-list {
        margin: 0 -9px !important;
    }

    .slick-sm-row-9 .slick-list .slick-slide {
        margin: 0 9px !important;
    }
}

.slick-row-10 .slick-list {
    margin: 0 -10px;
}

.slick-row-10 .slick-list .slick-slide {
    margin: 0 10px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-10 .slick-list {
        margin: 0 -10px !important;
    }

    .slick-sm-row-10 .slick-list .slick-slide {
        margin: 0 10px !important;
    }
}

.slick-row-11 .slick-list {
    margin: 0 -11px;
}

.slick-row-11 .slick-list .slick-slide {
    margin: 0 11px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-11 .slick-list {
        margin: 0 -11px !important;
    }

    .slick-sm-row-11 .slick-list .slick-slide {
        margin: 0 11px !important;
    }
}

.slick-row-12 .slick-list {
    margin: 0 -12px;
}

.slick-row-12 .slick-list .slick-slide {
    margin: 0 12px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-12 .slick-list {
        margin: 0 -12px !important;
    }

    .slick-sm-row-12 .slick-list .slick-slide {
        margin: 0 12px !important;
    }
}

.slick-row-13 .slick-list {
    margin: 0 -13px;
}

.slick-row-13 .slick-list .slick-slide {
    margin: 0 13px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-13 .slick-list {
        margin: 0 -13px !important;
    }

    .slick-sm-row-13 .slick-list .slick-slide {
        margin: 0 13px !important;
    }
}

.slick-row-14 .slick-list {
    margin: 0 -14px;
}

.slick-row-14 .slick-list .slick-slide {
    margin: 0 14px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-14 .slick-list {
        margin: 0 -14px !important;
    }

    .slick-sm-row-14 .slick-list .slick-slide {
        margin: 0 14px !important;
    }
}

.slick-row-15 .slick-list {
    margin: 0 -15px;
}

.slick-row-15 .slick-list .slick-slide {
    margin: 0 15px;
}

@media only screen and (max-width: 767.98px) {
    .slick-sm-row-15 .slick-list {
        margin: 0 -15px !important;
    }

    .slick-sm-row-15 .slick-list .slick-slide {
        margin: 0 15px !important;
    }
}

.fix {
    overflow: hidden;
}

.container {
    max-width: 1200px;
}

@media only screen and (max-width: 767.98px) {
    .container {
        max-width: 540px;
    }
}

@media only screen and (max-width: 575.98px) {
    .container {
        max-width: 450px;
    }
}

.bdr-bottom {
    border-bottom: 1px solid #f4bd55;
}

.bdr-bottom-2 {
    border-bottom: 1px solid #4c85bd;
}

.bdr-bottom-4 {
    border-bottom: 1px solid #50c8c9;
}

.bdr-bottom-5 {
    border-bottom: 1px solid #a3cb5c;
}

.bdr-top-3 {
    border-top: 1px solid #ebebeb;
}

.theme-color {
    background-color: #24babc;
}

.section-bg-color {
    background-color: #fff;
    padding: 15px;
}

.body-bg {
    background-color: #f2f2f2;
}

.bg-gray {
    background-color: #f2f2f2;
}

.bg-gray-2 {
    background-color: #f6f7f9;
}

.bg-blue {
    background-color: #3a78ff;
}

.bg-dark {
    background-color: #22282e !important;
}

.theme-color {
    background-color: #24babc;
}

.theme-color-2 {
    background-color: #2d70b1;
}

.theme-color-4 {
    background-color: #24babc;
}

.theme-color-5 {
    background-color: #93c240;
}

.theme-color-6 {
    background-color: #24babb;
}

.bg-black {
    background-color: #252525;
}

/* ----scroll to top css ----*/
.scroll-top {
    bottom: 50px;
    cursor: pointer;
    height: 50px;
    position: fixed;
    right: 20px;
    text-align: center;
    width: 50px;
    z-index: 9999;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    background-color: #24babc;
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.scroll-top i {
    line-height: 50px;
    color: #fff;
    font-size: 25px;
}

.scroll-top.not-visible {
    bottom: -50px;
    visibility: hidden;
    opacity: 0;
}

.custom-row .col-xl-3 {
    max-width: 23%;
    -webkit-flex-basis: 23%;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row .col-xl-3 {
        max-width: 28%;
        -webkit-flex-basis: 28%;
        -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
    }

    .custom-row .col-xl-3:first-child {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row .col-xl-3 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row .col-xl-6 {
    max-width: calc(100% - 46%);
    -webkit-flex-basis: calc(100% - 46%);
    -ms-flex-preferred-size: calc(100% - 46%);
    flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row .col-xl-6 {
        max-width: calc(100% - 28%);
        -webkit-flex-basis: calc(100% - 28%);
        -ms-flex-preferred-size: calc(100% - 28%);
        flex-basis: calc(100% - 28%);
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row .col-xl-6 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row-2 .col-xl-3 {
    max-width: 23%;
    -webkit-flex-basis: 23%;
    -ms-flex-preferred-size: 23%;
    flex-basis: 23%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row-2 .col-xl-3 {
        max-width: 28%;
        -webkit-flex-basis: 28%;
        -ms-flex-preferred-size: 28%;
        flex-basis: 28%;
    }

    .custom-row-2 .col-xl-3:first-child {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row-2 .col-xl-3 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.custom-row-2 .col-xl-6 {
    max-width: calc(100% - 46%);
    -webkit-flex-basis: calc(100% - 46%);
    -ms-flex-preferred-size: calc(100% - 46%);
    flex-basis: calc(100% - 46%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .custom-row-2 .col-xl-6 {
        max-width: calc(100% - 28%);
        -webkit-flex-basis: calc(100% - 28%);
        -ms-flex-preferred-size: calc(100% - 28%);
        flex-basis: calc(100% - 28%);
    }
}

@media only screen and (max-width: 767.98px) {
    .custom-row-2 .col-xl-6 {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

/*----------- header top area start -----------*/
.header-top-settings ul li {
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    margin-left: 40px;
    padding: 12px 0;
    position: relative;
    margin-left: 20px;
    padding-left: 20px;
    text-transform: uppercase;
}

.header-top-settings ul li:before {
    top: 50%;
    left: 0;
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background-color: #ebebeb;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-top-settings ul li span {
    cursor: auto;
    font-size: 14px;
    text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list {
    top: 100%;
    left: 0;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    padding: 15px 15px 20px;
    width: 150px;
    z-index: 9;
    border-bottom: 2px solid #24babc;
    background-color: #fff;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.header-top-settings ul li .dropdown-list li {
    margin-left: 0;
    padding: 0;
}

.header-top-settings ul li .dropdown-list li a {
    color: #252525;
    font-size: 14px;
    display: block;
    padding: 5px 0 3px;
    text-transform: capitalize;
}

.header-top-settings ul li .dropdown-list li a:hover {
    color: #24babc;
}

.header-top-settings ul li .dropdown-list li a img {
    vertical-align: inherit;
}

.header-top-settings ul li .dropdown-list li:before {
    display: none;
}

.header-top-settings ul li:hover .dropdown-list,
.header-top-settings ul li:hover .cart-list {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.header-top-settings ul li:first-child {
    margin-left: 0;
    padding-left: 0;
}

.header-top-settings ul li:first-child:before {
    display: none;
}

.header-links ul li {
    color: #fff;
    font-size: 14px;
    position: relative;
    padding-left: 20px;
    margin-left: 20px;
}

.header-links ul li a {
    color: #fff;
}

.header-links ul li a:hover {
    text-decoration: underline;
}

.header-links ul li:before {
    left: 0;
    top: 50%;
    width: 1px;
    height: 15px;
    content: "";
    position: absolute;
    background-color: #fff;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.header-links ul li:first-child:before {
    display: none;
}

/*----------- header top area end -----------*/
/*------- header middile area start -------*/
.header-middle-area {
    padding: 40px 0;
}

.header-middle-area-style_3 .search-box-inner .search-field {
    background-color: #f2f2f2;
}

.header-middle-area-style_3 .search-box-inner .search-btn {
    color: #24babc;
}

.header-middle-area-style_3 .header-configure-area ul li a {
    color: #252525;
}

.header-middle-area-style_3 .header-configure-area ul li a:hover {
    color: #24babc;
}

.search-box-inner {
    position: relative;
}

.search-box-inner .search-field {
    width: 100%;
    height: 48px;
    border: none;
    background-color: #fff;
    border-radius: 50px;
    padding: 0 55px 0 20px;
}

.search-box-inner .search-btn {
    width: 40px;
    height: 40px;
    font-size: 25px;
    line-height: 40px;
    text-align: center;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

/*---------- header mini cart start ----------*/
.header-configure-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.support-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .support-inner {
        margin-right: 5px;
    }
}

.support-icon {
    color: #fff;
    font-size: 30px;
    padding-right: 15px;
}

.support-info {
    color: #fff;
    font-size: 16px;
}

.support-info p {
    font-size: 14px;
    line-height: 1;
}

.support-info strong {
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
}

.header-support2 {
    text-align: right;
}

.header-support2 p {
    font-size: 14px;
    line-height: 1;
    color: #252525;
}

.header-support2 p i {
    color: #24babc;
    font-size: 25px;
    padding-right: 5px;
    vertical-align: middle;
}

.header-configure-area ul li {
    display: inline-block;
    font-size: 32px;
    line-height: 1;
    position: relative;
    margin-left: 30px;
}

.header-configure-area ul li a {
    color: #fff;
}

.header-configure-area ul li a:hover {
    color: #252525;
}

.mini-cart-wrap {
    position: relative;
}

.mini-cart-wrap ul.cart-list {
    width: 320px;
    top: 100%;
    right: 0;
    background-color: #fff;
    position: absolute;
    padding: 20px 20px 0 20px;
    z-index: 1111111;
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.mini-cart-wrap ul.cart-list li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: 0;
    margin-bottom: 20px;
}

.mini-cart-wrap ul.cart-list li .cart-img {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 70px;
    -ms-flex: 0 0 70px;
    flex: 0 0 70px;
    max-width: 70px;
    position: relative;
    max-height: 90px;
}

.mini-cart-wrap ul.cart-list li .cart-info {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 calc(100% - 90px);
    -ms-flex: 0 0 calc(100% - 90px);
    flex: 0 0 calc(100% - 90px);
    padding-left: 10px;
    text-align: left;
}

.mini-cart-wrap ul.cart-list li .cart-info h4 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
}

.mini-cart-wrap ul.cart-list li .cart-info h4 a {
    color: #252525;
}

.mini-cart-wrap ul.cart-list li .cart-info h4 a:hover {
    color: #24babc;
}

.mini-cart-wrap ul.cart-list li .cart-info span {
    color: #252525;
    font-size: 15px;
    margin-top: 5px;
    display: block;
    line-height: 1.4;
    font-weight: 400;
}

.mini-cart-wrap ul.cart-list li .cart-info span.cart-qty {
    font-size: 12px;
    color: #555;
    font-weight: 400;
}

.mini-cart-wrap ul.cart-list li .cart-info p {
    font-size: 12px;
    color: #555;
    text-transform: capitalize;
}

.mini-cart-wrap ul.cart-list li .del-icon {
    max-width: 20px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 20px;
    -ms-flex: 0 0 20px;
    flex: 0 0 20px;
    font-size: 15px;
    color: #555;
    text-align: center;
    cursor: pointer;
}

.mini-cart-wrap ul.cart-list li .del-icon:hover {
    color: #24babc;
}

.mini-cart-wrap ul.cart-list li .mini-cart-button {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.mini-cart-wrap ul.cart-list li .mini-cart-button .check-btn {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    display: block;
    padding: 10px 20px;
    background-color: #252525;
    text-align: center;
    text-transform: uppercase;
    margin-top: 10px;
}

.mini-cart-wrap ul.cart-list li .mini-cart-button .check-btn:hover {
    background-color: #24babc;
}

.mini-cart-wrap ul.cart-list li.mini-cart-price span {
    color: #252525;
    font-size: 15px;
    font-weight: 500;
    text-transform: uppercase;
}

.mini-cart-wrap ul.cart-list li:last-child {
    margin-top: -10px;
}

.mini-cart-wrap ul.cart-list.mobile-cart-list {
    opacity: 0;
    visibility: hidden;
}

.mini-cart-wrap ul.cart-list.mobile-cart-list.open {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.mini-cart-wrap:hover .cart-list {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

/*----------- header settinngs area end -----------*/
/*---------- main menu style start ---------*/
.main-menu ul {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.main-menu ul li {
    position: relative;
    padding: 15px 5px;
}

.main-menu ul li a {
    color: #252525;
    font-size: 14px;
    padding: 4px 15px;
    font-weight: 400;
    display: block;
    text-transform: uppercase;
    border-radius: 5px;
}

.main-menu ul li a i {
    font-size: 14px;
    padding: 0 3px;
}

.main-menu ul li:first-child {
    padding-left: 0;
}

.main-menu ul li:hover>a,
.main-menu ul li.active>a {
    color: #fff;
    background-color: #24babc;
}

.main-menu ul li ul.dropdown {
    top: 100%;
    left: 0;
    width: 220px;
    position: absolute;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    z-index: 99999;
    padding: 20px 0;
    pointer-events: none;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.dropdown li {
    margin-right: 0;
    border-right: none;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 0;
}

.main-menu ul li ul.dropdown li a {
    color: #555;
    font-size: 14px;
    font-weight: 400;
    padding: 5px 15px;
    text-transform: capitalize;
}

.main-menu ul li ul.dropdown li a i {
    float: right;
    padding-top: 5px;
}

.main-menu ul li ul.dropdown li:hover>a {
    color: #24babc;
    background-color: #fff;
}

.main-menu ul li ul.dropdown li ul.dropdown {
    top: 100%;
    left: 100%;
    position: absolute;
    width: 250px;
    opacity: 0;
    visibility: hidden;
}

.main-menu ul li ul.dropdown li:hover ul.dropdown {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.main-menu ul li ul.megamenu {
    width: 800px;
    -webkit-box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
    box-shadow: 0 0 6.65px 0.35px rgba(0, 0, 0, 0.15);
}

.main-menu ul li ul.megamenu li {
    -webkit-flex-basis: 25%;
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
}

.main-menu ul li ul.megamenu li.mega-title>a {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
}

.main-menu ul li ul.megamenu li ul li {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.main-menu ul li ul.megamenu li:hover>a {
    color: #24babc;
}

.main-menu ul li:hover ul.dropdown {
    opacity: 1;
    visibility: visible;
    pointer-events: visible;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
}

.main-menu ul li:hover ul.megamenu {
    opacity: 1;
    visibility: visible;
}

.main-menu.home-main ul li ul.megamenu {
    margin-left: -92px;
}

/*---------- main menu style end ---------*/
/*------ sticky menu style start ------*/
.sticky.is-sticky {
    top: 0;
    position: fixed;
    width: 100%;
    z-index: 9999;
    -webkit-box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
    -webkit-animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
    animation: 900ms cubic-bezier(0.2, 1, 0.22, 1) 0s normal none 1 running fadeInDown;
}

.sticky.is-sticky .mobile-header-top {
    display: none;
}

.sticky.is-sticky.mobile-header {
    background-color: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .sticky.is-sticky .category-toggle-wrap {
        display: none;
    }
}

/*------ sticky menu style end ------*/
/*----- mobile menu start -----*/
.mobile-header .category-toggle {
    padding: 8px 15px;
}

.mobile-header-top {
    border-bottom: 1px solid #ebebeb;
}

.mobile-header-top .header-top-settings {
    float: none;
}

.mobile-logo {
    max-width: 140px;
    width: 100%;
}

.mobile-main-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .mobile-main-header {
        border-bottom: 1px solid #ebebeb;
    }
}

.mobile-main-header .mobile-menu-toggler {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap {
    display: inline-block;
    font-size: 24px;
    line-height: 1;
    position: relative;
    margin-left: 30px;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap a {
    color: #252525;
}

.mobile-main-header .mobile-menu-toggler .mini-cart-wrap .notification {
    font-size: 13px;
    color: #fff;
    width: 16px;
    height: 16px;
    line-height: 16px;
    border-radius: 50%;
    text-align: center;
    font-weight: 600;
    right: -8px;
    top: 0;
    position: absolute;
    background-color: #2d70b1;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn {
    margin-left: 15px;
}

.mobile-main-header .mobile-menu-toggler .mobile-menu-btn .off-canvas-btn {
    font-size: 30px;
    cursor: pointer;
    padding: 10px 15px;
    line-height: 1;
    padding-right: 0;
}

.mobile-navigation {
    overflow: hidden;
    max-height: 250px;
}

.mobile-navigation nav {
    height: 100%;
}

.mobile-menu {
    margin-top: 30px;
    height: 100%;
    overflow: auto;
    padding-right: 30px;
    margin-right: -30px;
}

@media only screen and (max-width: 479.98px) {
    .mobile-menu {
        margin-top: 15px;
    }
}

.mobile-menu li>a {
    font-size: 15px;
    color: #252525;
    text-transform: capitalize;
    line-height: 18px;
    position: relative;
    display: block;
    padding: 10px 0;
}

.mobile-menu li>a:hover {
    color: #24babc;
}

.mobile-menu li ul li {
    border: none;
}

.mobile-menu li ul li a {
    font-size: 14px;
    text-transform: capitalize;
    padding: 10px 15px 8px;
}

.mobile-menu .has-children,
.mobile-menu .menu-item-has-children {
    position: relative;
}

.mobile-menu .has-children:before,
.mobile-menu .menu-item-has-children:before {
    top: 0;
    right: 0;
    color: #252525;
    font-size: 20px;
    content: '\f489';
    position: absolute;
    font-family: "Ionicons";
}

.mobile-menu .has-children.icon-show:before,
.mobile-menu .menu-item-has-children.icon-show:before {
    content: '\f462';
}

/*----- mobile menu end -----*/
/*------ offcanvas widget area start ------*/
.offcanvas-widget-area {
    margin-top: auto;
    padding-bottom: 30px;
}

@media only screen and (max-width: 479.98px) {
    .offcanvas-widget-area {
        padding-bottom: 0;
    }
}

.off-canvas-contact-widget li {
    color: #555;
    font-size: 15px;
    margin-bottom: 5px;
}

.off-canvas-contact-widget li i {
    width: 20px;
}

.off-canvas-contact-widget li a {
    color: #555;
}

.off-canvas-contact-widget li a:hover {
    color: #24babc;
}

.off-canvas-social-widget {
    margin-top: 20px;
}

.off-canvas-social-widget a {
    color: #555;
    font-size: 18px;
    display: inline-block;
    margin-right: 15px;
}

.off-canvas-social-widget a:hover {
    color: #24babc;
}

/*------ offcanvas widget area end ------*/
.off-canvas-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    z-index: 9999;
}

.off-canvas-wrapper.open {
    opacity: 1;
    visibility: visible;
}

.off-canvas-wrapper.open .off-canvas-inner-content {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
}

.off-canvas-wrapper .off-canvas-overlay {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    cursor: url("../../../assets/images/icon/cancel.png"), auto;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.off-canvas-wrapper .off-canvas-inner-content {
    background-color: #fff;
    width: 300px;
    padding: 30px;
    height: 100%;
    position: relative;
    -webkit-transform: translateX(calc(-100% - 50px));
    -ms-transform: translateX(calc(-100% - 50px));
    transform: translateX(calc(-100% - 50px));
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
    .off-canvas-wrapper .off-canvas-inner-content {
        width: 260px;
        padding: 15px;
    }
}

.off-canvas-wrapper .btn-close-off-canvas {
    top: 0;
    left: 100%;
    width: 50px;
    height: 50px;
    font-size: 30px;
    background-color: #24babc;
    color: #fff;
    line-height: 50px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

@media only screen and (max-width: 479.98px) {
    .off-canvas-wrapper .btn-close-off-canvas {
        width: 40px;
        height: 40px;
        font-size: 20px;
        line-height: 40px;
    }
}

.off-canvas-wrapper .btn-close-off-canvas i {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    display: block;
}

.off-canvas-wrapper .btn-close-off-canvas:hover i {
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.off-canvas-wrapper .off-canvas-inner {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-left: auto;
    height: 100%;
}

.search-box-offcanvas form {
    position: relative;
}

.search-box-offcanvas form input {
    color: #666;
    font-size: 13px;
    width: 100%;
    height: 40px;
    border: none;
    padding: 0 40px 0 10px;
    background-color: #f2f2f2;
}

.search-box-offcanvas form .search-btn {
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    line-height: 42px;
    font-size: 20px;
    color: #555;
    position: absolute;
}

.search-box-offcanvas form .search-btn:hover {
    color: #24babc;
}

/*------- mobile top bar settings start -------*/
.mobile-settings {
    padding-top: 30px;
}

.mobile-settings li {
    margin-bottom: 5px;
}

.mobile-settings .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle {
    font-size: 14px;
    color: #555;
    cursor: pointer;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:hover {
    color: #24babc;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-toggle:after {
    display: none;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu {
    padding: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    border-color: #ebebeb;
}

.mobile-settings .nav .mobile-top-dropdown .dropdown-menu .dropdown-item {
    font-size: 13px;
    color: #555;
    padding: 6px 15px;
}

/*------- mobile top bar settings end -------*/
.category-dropdown-wrapper {
    height: 100%;
    -webkit-box-shadow: -1px 0px 3px 0px #cccccc;
    box-shadow: -1px 0px 3px 0px #cccccc;
}

@media only screen and (max-width: 767.98px) {
    .category-dropdown-wrapper {
        display: none;
    }
}

.category-toggle-wrap {
    position: relative;
}

.category-toggle-wrap .category-toggle {
    width: 100%;
    border: none;
    cursor: pointer;
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 18px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap .category-toggle {
        padding: 10px 0;
    }
}

.category-toggle-wrap .category-toggle i {
    color: #252525;
    font-size: 25px;
    line-height: 1;
    padding-right: 15px;
}

.category-toggle-wrap .category-toggle span {
    line-height: 1;
    margin-left: auto;
}

.category-toggle-wrap .category-toggle span i {
    font-size: 14px;
    padding-right: 0;
}

.category-toggle-wrap .category-toggle-style_3 {
    background: #fff;
    padding: 18px 18px;
    border-bottom: 1px solid #ebebeb;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap .category-toggle-style_3 {
        padding: 10px 18px;
    }
}

.category-toggle-wrap nav.category-menu {
    border-top: none;
    top: 100%;
    left: 0;
    width: 100%;
    max-width: 269px;
    position: absolute;
    z-index: 11;
    display: none;
    background-color: #fff;
}

.category-toggle-wrap nav.category-menu-style_2 {
    display: block;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu-style_2 {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu-style_3 {
    display: block;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .category-toggle-wrap nav.category-menu-style_3 {
        display: none;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu {
        max-width: 100%;
        -webkit-box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
        box-shadow: 0 2px 5px 0.35px rgba(0, 0, 0, 0.15);
    }
}

.category-toggle-wrap nav.category-menu>ul.categories-list {
    padding: 17px 20px;
}

.category-toggle-wrap nav.category-menu>ul.categories-list-style_3 {
    padding: 8px 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul {
        max-height: 300px;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 575.98px) {
    .category-toggle-wrap nav.category-menu>ul {
        max-height: 220px;
        overflow-y: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li {
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children {
    position: relative;
}

.category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children>a::after {
    content: "\f105";
    position: absolute;
    right: 0;
    top: 50%;
    line-height: 1;
    font-family: "FontAwesome";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children>a::after {
        display: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li.menu-item-has-children>a::after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .menu-expand {
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 50px;
    width: 40px;
    line-height: 50px;
    z-index: 9;
    text-align: center;
}

.category-toggle-wrap nav.category-menu>ul>li>a {
    display: block;
    padding: 10px 0;
    line-height: 24px;
    font-size: 14px;
    color: #555;
    position: relative;
    text-transform: capitalize;
    border-bottom: 1px solid #ebebeb;
}

.category-toggle-wrap nav.category-menu>ul>li>a:hover {
    color: #24babc;
}

.category-toggle-wrap nav.category-menu>ul>li:first-child a {
    padding-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li:first-child a {
        padding-top: 10px;
    }
}

.category-toggle-wrap nav.category-menu>ul>li:last-child a {
    padding-bottom: 0;
    border-bottom: none;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li:last-child a {
        padding-bottom: 10px;
    }
}

.category-toggle-wrap nav.category-menu>ul>li:hover .category-mega-menu {
    top: 0;
    z-index: 9;
    opacity: 1;
    visibility: visible;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
    position: absolute;
    left: calc(100% + 20px);
    top: 110%;
    width: 800px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 30px;
    opacity: 0;
    z-index: -9;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
        width: 600px;
        padding-bottom: 5px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu {
        position: static;
        z-index: 9;
        opacity: 1;
        visibility: visible;
        background-color: transparent;
        padding: 0;
        width: 100%;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
        border: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column {
    width: 400px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column>li {
    width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.two-column>li {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column {
    width: 600px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column>li {
    width: 33.33%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu.three-column>li {
        width: 100%;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
    float: left;
    width: 25%;
    padding: 0 15px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
        width: 50%;
        margin-bottom: 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li {
        -webkit-box-flex: 1;
        -webkit-flex: 1 0 100%;
        -ms-flex: 1 0 100%;
        flex: 1 0 100%;
        padding: 0;
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a {
    display: block;
    font-size: 15px;
    font-weight: 500;
    color: #252525;
    text-transform: uppercase;
    margin-bottom: 10px;
    position: relative;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:before,
.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:after {
    left: 0;
    bottom: -3px;
    content: "";
    position: absolute;
    height: 2px;
    width: 100%;
    background-color: #ebebeb;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a:after {
    width: 50%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>a {
        padding: 10px 25px 10px 35px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
        margin: 0;
        text-transform: inherit;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(odd) a:after {
    background-color: red;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(odd) a:after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(even) a:after {
    background-color: #24babc;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li:nth-child(even) a:after {
        display: none;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child {
    border: none;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child {
    margin-bottom: 0;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
    display: block;
    font-size: 14px;
    color: #606060;
    line-height: 30px;
    padding-bottom: 4px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
        padding: 10px 25px 10px 45px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
    }
}

@media only screen and (max-width: 767.98px) {
    .category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a {
        padding: 10px 25px 10px 45px;
        line-height: 30px;
        font-size: 14px;
        color: #606060;
        font-weight: 400;
        position: relative;
    }
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li a:hover {
    color: #24babc;
}

.category-toggle-wrap nav.category-menu>ul>li .category-mega-menu>li>ul li:last-child a {
    border-bottom: 0;
}

/*--------- hero slider start ---------*/
.hero-slider-item {
    height: 585px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .hero-slider-item {
        height: 400px;
    }
}

@media only screen and (max-width: 575.98px) {
    .hero-slider-item {
        height: 350px;
    }
}

.hero-slider-item.stlder-style_2 {
    height: 552px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .hero-slider-item.stlder-style_2 {
        height: 424px;
    }
}

@media only screen and (max-width: 767.98px) {
    .hero-slider-item.stlder-style_2 {
        height: 350px;
    }
}

.hero-slider-content h2 {
    color: #24babc;
    font-size: 30px;
    font-weight: 500;
    line-height: 1.2;
    padding-bottom: 5px;
    text-transform: uppercase;
}

@media only screen and (max-width: 575.98px) {
    .hero-slider-content h2 {
        font-size: 22px;
    }
}

.hero-slider-content h1 {
    color: #fff;
    font-size: 45px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
}

@media only screen and (max-width: 575.98px) {
    .hero-slider-content h1 {
        font-size: 35px;
    }
}

.hero-slider-content h3 {
    color: #fff;
    font-size: 22px;
    font-weight: 400;
    line-height: 1.3;
    text-transform: uppercase;
}

@media only screen and (max-width: 575.98px) {
    .hero-slider-content h3 {
        font-size: 18px;
    }
}

.hero-slider-content .btn-hero,
.hero-slider-content .btn-outline {
    margin-top: 25px;
}

.hero-slider-content_2 {
    padding-left: 50px;
}

@media only screen and (max-width: 575.98px) {
    .hero-slider-content_2 {
        padding-left: 30px;
    }
}

/*------ hero slider for home 4 end ------*/
/* .slick-current {
    .hero-slider-content {
        h2 {
            animation-name: fadeInUp;
            animation-duration: 1s; 
        }
        h1 {
            animation-name: fadeInUp;
            animation-duration: 1.5s; 
        }
        h3 {
            animation-name: fadeInUp;
            animation-duration: 2s; 
        }
        a {
            animation-name: fadeInUp;
            animation-duration: 2.5s; 
        }
    }
} */
/*------- service features area start -------*/
.service-features-inner {
    padding: 37px 0;
    position: relative;
}

@media only screen and (max-width: 767.98px) {
    .service-features-inner {
        display: block;
        padding: 47px 0;
        text-align: center;
    }
}

.single-features-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 40px;
    border-right: 1px solid #eee;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .single-features-item {
        display: block;
        padding: 0 2px;
        text-align: center;
        border: none;
    }
}

@media only screen and (max-width: 767.98px) {
    .single-features-item {
        display: block;
        padding: 0;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 479.98px) {
    .single-features-item {
        padding: 0 15px;
    }
}

.single-features-item .features-icon {
    color: #24babc;
    font-size: 40px;
    padding-right: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .single-features-item .features-icon {
        padding-right: 0;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767.98px) {
    .single-features-item .features-icon {
        margin-bottom: 10px;
    }
}

.single-features-item .features-icon i {
    line-height: 1;
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
    display: block;
}

.single-features-item .features-content h5 {
    color: #252525;
    font-size: 16px;
    line-height: 1.2;
    font-weight: 500;
    padding-bottom: 3px;
    text-transform: uppercase;
}

.single-features-item .features-content p {
    color: #555;
    font-size: 14px;
    line-height: 1.5;
}

.single-features-item:hover .features-icon i {
    -webkit-animation: bounceIn 1s ease-in-out;
    animation: bounceIn 1s ease-in-out;
}

.footer-feature-item {
    border-right: none;
    padding: 0;
}

@media only screen and (max-width: 767.98px) {
    .footer-feature-item {
        text-align: center;
    }
}

.footer-feature-item .features-icon {
    font-size: 45px;
}

.footer-feature-item .features-content h5 {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-transform: inherit;
}

.footer-feature-item .features-content p {
    color: #999;
}

.service-features-inner [class*="col-"]:last-child .single-features-item,
.footer-features-inner [class*="col-"]:last-child .single-features-item {
    margin-bottom: 0;
}

/*-------- service features area end --------*/
/*---------- section title start ----------*/
.section-header {
    padding: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
}

.section-header-deals {
    padding-bottom: 10px;
    border-bottom: 1px solid #ebebeb;
}

@media only screen and (max-width: 575.98px) {
    .section-header {
        display: block;
    }
}

.section-header-inner_2 {
    margin-bottom: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.section-title {
    line-height: 1;
}

.section-title h4 {
    color: #252525;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
    display: inline-block;
    text-transform: capitalize;
}

.section-title-deals h4 {
    color: #252525;
    font-size: 22px;
    font-weight: 400;
    line-height: 1;
    display: inline-block;
    text-transform: capitalize;
}

/*---------- section title end ----------*/
/*------- Buttons Style here -------*/
.btn {
    border-radius: 0;
    font-size: 15px;
    color: #24babc;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    display: inline-block;
    padding: 10px 25px;
    border: 1px solid #24babc;
}

.btn:hover {
    color: #fff;
    background-color: #24babc;
}

.btn-hero {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    padding: 10px 20px;
    border-radius: 3px;
    display: inline-block;
    background-color: #24babc;
    text-transform: uppercase;
}

.btn-hero:hover {
    color: #fff;
    background-color: #1c9091;
}

.btn-read {
    color: #252525;
    font-size: 14px;
    padding: 5px 20px;
    background-color: transparent;
    text-transform: capitalize;
    border-color: #252525;
}

.btn-read:hover {
    background-color: #24babc;
    border-color: #24babc;
}

.btn__bg {
    color: #fff;
    background-color: #24babc;
    border-color: #24babc;
}

.btn__bg:hover {
    border-color: #252525;
    background-color: #252525;
}

.btn-outline {
    border: 1px solid #fff;
    color: #fff;
    font-size: 15px;
    display: inline-block;
    text-transform: uppercase;
    padding: 5px 20px;
    border-radius: 4px;
}

.btn-outline:hover {
    color: #fff;
    border-color: #24babc;
    background-color: #24babc;
}

.btn-cart {
    padding: 4px 15px;
    border-radius: 4px;
}

.btn-cart i {
    font-size: 20px;
    padding-right: 5px;
}

/*------- product item start -------*/
.product-item {
    overflow: hidden;
    margin-bottom: 30px;
}

.product-item:hover .product-content {
    -webkit-transform: translateY(-45px);
    -ms-transform: translateY(-45px);
    transform: translateY(-45px);
}

.product-thumb {
    position: relative;
    text-align: center;
}

.product-thumb:hover .add-to-links {
    opacity: 1;
    visibility: visible;
    -webkit-animation: zoomIn 300ms;
    animation: zoomIn 300ms;
}

.product-content {
    padding: 5px 0;
    text-align: center;
    position: relative;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
}

.product-content .product-name {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.product-content .product-name a {
    color: #252525;
}

.product-content .product-name a:hover {
    color: #24babc;
}

.product-content .product-item-action {
    left: 0;
    right: 0;
    top: 100%;
    position: absolute;
}

.product-content .price-box {
    padding: 5px 0;
}

.add-to-links {
    left: 0;
    right: 0;
    top: 10px;
    text-align: center;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.add-to-links a {
    display: inline-block;
    font-size: 25px;
    color: #252525;
    margin-right: 15px;
    line-height: 1;
}

.add-to-links a:last-child {
    margin-right: 0;
}

.add-to-links a:hover {
    color: #24babc;
}

.product-badge {
    display: inline-grid;
    padding: 12px 0;
}

.product-badge .product-label {
    color: #fff;
    font-size: 13px;
    font-weight: 600;
    line-height: 1;
    height: 24px;
    padding: 0 10px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-badge .product-label:before {
    content: "";
    top: 0;
    left: 100%;
    position: absolute;
    border-left: 12px solid #3fcc8d;
    border-top: 12px solid transparent;
    border-bottom: 12px solid transparent;
}

.product-badge .product-label.new {
    background-color: #3fcc8d;
}

.product-badge .product-label.discount {
    background-color: #ff5e00;
}

.product-badge .product-label.discount:before {
    border-left: 12px solid #ff5e00;
}

.ratings {
    color: #f9bd22;
    font-size: 14px;
}

.price-old {
    font-size: 15px;
    color: #999;
    padding-left: 4px;
}

.price-regular {
    font-size: 15px;
    line-height: 1;
    color: #cf0016;
}

/*------- feature product item end -------*/
/*-------- product list item start --------*/
.product-content-list .ratings {
    padding-bottom: 5px;
}

.product-content-list .product-name {
    padding-bottom: 5px;
}

.product-content-list .product-name a {
    color: #252525;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 400;
}

.product-content-list .product-name a:hover {
    color: #24babc;
}

.product-content-list p {
    padding-bottom: 20px;
}

.product-list-item {
    position: relative;
    padding: 10px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 30px;
    border: 1px solid #ebebeb;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-list-item {
        padding: 20px;
    }
}

@media only screen and (max-width: 575.98px) {
    .product-list-item {
        display: block !important;
    }
}

.product-list-item .product-thumb {
    max-width: 30%;
    -webkit-flex-basis: 30%;
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
}

@media only screen and (max-width: 575.98px) {
    .product-list-item .product-thumb {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.product-list-item .product-content-list {
    padding-left: 20px;
    max-width: calc(100% - 30%);
    -webkit-flex-basis: calc(100% - 30%);
    -ms-flex-preferred-size: calc(100% - 30%);
    flex-basis: calc(100% - 30%);
}

@media only screen and (max-width: 575.98px) {
    .product-list-item .product-content-list {
        padding-left: 0;
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.product-list-item .product-content-list .price-old,
.product-list-item .product-content-list .price-regular {
    font-size: 20px;
}

/*-------- product list item end --------*/
/*------- daily deals area start -------*/
.deals-wrapper {
    padding: 30px;
}

@media only screen and (max-width: 767.98px) {
    .deals-wrapper {
        padding: 15px;
    }
}

.deals-item-wrapper {
    margin-top: 30px;
}

.deals-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 575.98px) {
    .deals-item {
        display: block;
    }
}

.deals-item:hover .deals-thumb .add-to-links {
    opacity: 1;
    visibility: visible;
    -webkit-animation: zoomIn 300ms ease-in-out;
    animation: zoomIn 300ms ease-in-out;
}

.deals-item-style_2 {
    padding: 30px 20px;
}

.deals-item-style_2 .product-countdown {
    margin-top: 25px;
}

.deals-thumb {
    max-width: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    position: relative;
}

@media only screen and (max-width: 575.98px) {
    .deals-thumb {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }
}

.deals-content {
    max-width: 50%;
    -webkit-flex-basis: 50%;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    padding-left: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .deals-content {
        padding-left: 4px;
    }
}

@media only screen and (max-width: 575.98px) {
    .deals-content {
        max-width: 100%;
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        padding-left: 0;
    }
}

.deals-content h4 {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    color: #252525;
}

.deals-content h4 a {
    color: #252525;
}

.deals-content h4 a:hover {
    color: #24babc;
}

.deals-content .product-name {
    padding-bottom: 5px;
}

.deals-content .price-box {
    padding-top: 8px;
    padding-bottom: 20px;
}

.deals-content .price-old {
    font-size: 16px;
}

.deals-content .price-regular {
    font-size: 18px;
    font-weight: 500;
}

/*------- daily deals area end -------*/
/*------ countdown style start ------*/
.product-countdown {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 15px;
}

.product-countdown .single-countdown {
    text-align: center;
    margin-right: 10px;
    padding: 10px 0;
    border-radius: 5px;
    width: 50px;
    background-color: #eaeaea;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .product-countdown .single-countdown {
        margin-right: 5px;
        width: 45px;
    }
}

.product-countdown .single-countdown__time {
    color: #252525;
    font-size: 18px;
    font-weight: 400;
    display: block;
    line-height: 1;
}

.product-countdown .single-countdown__text {
    display: block;
    font-size: 12px;
    color: #555;
    margin-top: 5px;
    line-height: 1;
    text-transform: capitalize;
}

.product-countdown .single-countdown:first-child {
    background-color: #252525;
}

.product-countdown .single-countdown:first-child .single-countdown__time,
.product-countdown .single-countdown:first-child .single-countdown__text {
    color: #fff;
}

/*------ countdown style end ------*/
/*------- banner feature  start -------*/
.img-container {
    position: relative;
}

.img-container:before,
.img-container:after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    pointer-events: none;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: top left;
    -ms-transform-origin: top left;
    transform-origin: top left;
}

.img-container:after {
    bottom: 0;
    right: 0;
    -webkit-transform-origin: bottom right;
    -ms-transform-origin: bottom right;
    transform-origin: bottom right;
}

.img-container:hover:before,
.img-container:hover:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0;
    -webkit-transition-duration: 1.3s;
    -o-transition-duration: 1.3s;
    transition-duration: 1.3s;
}

.img-container img {
    width: 100%;
}

@media only screen and (max-width: 767.98px) {
    .section-banner {
        margin-top: 50px;
    }
}

.section-banner img {
    width: 100%;
}

/*------- banner statistics end -------*/
/*------- Feature categories section start -------*/
@media only screen and (max-width: 575.98px) {
    .feature-menu {
        margin-top: 15px;
    }
}

.feature-menu ul li {
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    margin-left: 10px;
    text-transform: uppercase;
}

.feature-menu ul li:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 479.98px) {
    .feature-menu ul li {
        font-size: 13px;
        margin-left: 10px;
    }
}

.feature-menu ul li a {
    color: #252525;
    padding: 10px 10px;
    display: block;
    border-radius: 3px;
}

.feature-menu ul li a:hover,
.feature-menu ul li a.active {
    color: #fff;
    background-color: #24babc;
}

/*------- Feature categories section end -------*/
/*------ feature product area start ------*/
@media only screen and (max-width: 575.98px) {
    .feature-product-slider {
        padding: 30px 0;
    }
}

.feature-product-slider .product-item {
    margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-item-wrapper {
        padding: 30px;
    }
}

.most-viewed {
    padding: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .new-products-area {
        margin-top: 48px;
    }
}

/*------ feature product area end ------*/
/*-------- group list product item start --------*/
.group-list-wrapper {
    background-color: #fff;
    padding: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .group-list-wrapper {
        margin-bottom: 50px;
    }
}

.group-list-products [class*="col-"]:last-child .group-list-wrapper {
    margin-bottom: 0;
}

/*------ group list product title start ------*/
.group-list-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #ebebeb;
}

.group-list-title {
    font-size: 20px;
    font-weight: 400;
    line-height: 1.4;
    color: #252525;
    padding-bottom: 10px;
    text-transform: capitalize;
}

/*------ group list product title end ------*/
/*------ group list item start ------*/
.group-list-item-wrapper {
    margin-top: 25px;
}

.group-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 30px;
}

.group-item:hover .add-to-links {
    opacity: 1;
    visibility: visible;
    -webkit-animation: zoomIn 300ms;
    animation: zoomIn 300ms;
}

.group-item-thumb {
    max-width: 100px;
    -webkit-flex-basis: 100px;
    -ms-flex-preferred-size: 100px;
    flex-basis: 100px;
}

@media only screen and (max-width: 767.98px) {
    .group-item-thumb {
        max-width: 75px;
        -webkit-flex-basis: 75px;
        -ms-flex-preferred-size: 75px;
        flex-basis: 75px;
    }
}

.group-item-desc {
    position: relative;
    padding-left: 20px;
    max-width: calc(100% - 100px);
    -webkit-flex-basis: calc(100% - 100px);
    -ms-flex-preferred-size: calc(100% - 100px);
    flex-basis: calc(100% - 100px);
}

@media only screen and (max-width: 767.98px) {
    .group-item-desc {
        max-width: calc(100% - 75px);
        -webkit-flex-basis: calc(100% - 75px);
        -ms-flex-preferred-size: calc(100% - 75px);
        flex-basis: calc(100% - 75px);
    }
}

.group-item-desc h5 {
    font-size: 15px;
    line-height: 1.5;
    font-weight: 400;
    text-transform: capitalize;
}

.group-item-desc h5 a {
    color: #252525;
}

.group-item-desc h5 a:hover {
    color: #24babc;
}

/*------ group list item end ------*/
.group-list-item-wrapper .slick-slide>div:last-child .group-item {
    margin-bottom: 0;
}

.add-to-links.group-action-link {
    top: 0;
    right: 15px;
    left: auto;
}

@media only screen and (max-width: 479.98px) {
    .add-to-links.group-action-link {
        right: 0;
    }
}

.add-to-links.group-action-link a {
    display: block;
    font-size: 22px;
    margin-right: 0;
    margin-bottom: 10px;
}

.add-to-links.group-action-link a:last-child {
    margin-bottom: 0;
}

/*-------- group list product item start --------*/
/*-------- brand area start --------*/
.brand-logo-slider {
    padding: 40px 0;
}

.brand-logo-slider .brand-item img {
    margin: 0 auto;
}

/*-------- brand area end --------*/
/*-------- Newsletter box start --------*/
.newsletter-area {
    padding: 50px 0;
}

@media only screen and (max-width: 767.98px) {
    .newsletter-area {
        padding: 42px 0 50px;
    }
}

.newsletter-title {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767.98px) {
    .newsletter-title {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin-bottom: 20px;
    }
}

.newsletter-title .newsletter-icon {
    font-size: 32px;
    color: #fff;
    line-height: 1;
    padding-right: 10px;
}

.newsletter-title h3 {
    color: #fff;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    text-transform: capitalize;
}

.newsletter-inner {
    padding: 0 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .newsletter-inner {
        padding: 0;
    }
}

@media only screen and (max-width: 575.98px) {
    .newsletter-inner {
        padding: 0 20px;
    }
}

@media only screen and (max-width: 479.98px) {
    .newsletter-inner {
        padding: 0;
    }
}

.newsletter-inner form {
    position: relative;
}

.newsletter-inner .news-field {
    width: 100%;
    height: 44px;
    display: block;
    border: none;
    line-height: 1;
    padding: 0 110px 0 15px;
    color: #fff;
    background-color: #3a424b;
    position: relative;
    border-radius: 5px;
}

.newsletter-inner .news-btn {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    height: 44px;
    color: #fff;
    line-height: 1;
    font-weight: 400;
    padding: 0 15px;
    display: inline-block;
    background-color: #24babc;
    border-radius: 0 5px 5px 0;
}

.newsletter-inner .news-btn:hover {
    background-color: #1c9091;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .payment-method {
        text-align: center;
        margin-top: 30px;
    }
}

/*-------- Newsletter box end --------*/
.mailchimp-success {
    color: #fff;
    font-size: 16px;
}

/*----- breadcrumb style css start -----*/
.breadcrumb-area {
    padding-top: 50px;
}

.breadcrumb-area-style_2 {
    background-color: #f2f2f2;
    padding: 67px 0;
}

@media only screen and (max-width: 767.98px) {
    .breadcrumb-area-style_2 {
        padding: 35px 0 30px;
    }
}

.breadcrumb-area-style_2 .breadcrumb-wrap {
    padding: 0;
    background-color: transparent;
    text-align: center;
}

.breadcrumb-wrap {
    padding: 67px 0;
    text-align: center;
    background-color: #fff;
}

@media only screen and (max-width: 767.98px) {
    .breadcrumb-wrap {
        padding: 30px 0;
    }
}

.breadcrumb-wrap h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    color: #252525;
    display: block;
    padding-bottom: 10px;
    text-transform: uppercase;
}

@media only screen and (max-width: 767.98px) {
    .breadcrumb-wrap h1 {
        font-size: 16px;
    }
}

.breadcrumb-wrap nav .breadcrumb {
    background: transparent;
    margin-bottom: 0;
    padding: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.breadcrumb-wrap nav .breadcrumb .breadcrumb-item a {
    color: #333333;
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    text-transform: capitalize;
}

.breadcrumb-wrap nav .breadcrumb .breadcrumb-item a:hover {
    text-decoration: underline;
}

.breadcrumb-wrap nav .breadcrumb .breadcrumb-item:before {
    color: #7e7e7e;
    content: "\f3d3";
    font-size: 12px;
    margin: 0 8px;
    font-family: "Ionicons";
}

@media only screen and (max-width: 479.98px) {
    .breadcrumb-wrap nav .breadcrumb .breadcrumb-item:before {
        margin: 0 5px;
    }
}

.breadcrumb-wrap nav .breadcrumb .breadcrumb-item:first-child::before {
    display: none;
}

.breadcrumb-wrap nav .breadcrumb .breadcrumb-item.active {
    color: #24babc;
    font-size: 16px;
    font-weight: 400;
    text-transform: capitalize;
}

/*----- breadcrumb style css end -----*/
/*----- shop Sidebar start -----*/
.sidebar-wrapper .sidebar-single:last-child {
    margin-bottom: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .sidebar-wrapper {
        margin-top: 50px;
    }
}

.sidebar-single {
    margin-bottom: 30px;
    padding: 25px 15px;
    background-color: #fff;
}

.sidebar-single .sidebar-title {
    padding-bottom: 15px;
    position: relative;
}

.sidebar-single .sidebar-title h3 {
    color: #252525;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    margin-top: -3px;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
}

/*------ shop categories area start ------*/
.shop-categories ul {
    margin-top: 0;
}

.shop-categories ul.mobile-menu {
    overflow: hidden;
}

.shop-categories ul li a {
    color: #555;
}

.shop-categories ul li:last-child a {
    padding-bottom: 0;
}

/*------ shop categories area end ------*/
/*----- price Sidebar start -----*/
.radio-container li,
.checkbox-container li {
    padding: 12px 0;
}

.radio-container li .custom-radio label,
.radio-container li .custom-radio .custom-control-label,
.radio-container li .custom-checkbox label,
.radio-container li .custom-checkbox .custom-control-label,
.checkbox-container li .custom-radio label,
.checkbox-container li .custom-radio .custom-control-label,
.checkbox-container li .custom-checkbox label,
.checkbox-container li .custom-checkbox .custom-control-label {
    color: #555;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.radio-container li .custom-radio label:hover,
.radio-container li .custom-radio .custom-control-label:hover,
.radio-container li .custom-checkbox label:hover,
.radio-container li .custom-checkbox .custom-control-label:hover,
.checkbox-container li .custom-radio label:hover,
.checkbox-container li .custom-radio .custom-control-label:hover,
.checkbox-container li .custom-checkbox label:hover,
.checkbox-container li .custom-checkbox .custom-control-label:hover {
    color: #24babc;
}

.radio-container li:last-child,
.checkbox-container li:last-child {
    padding-bottom: 0;
}

/*----- price Sidebar end -----*/
/*----- shop Sidebar end -----*/
/*------ pagination area style start ------*/
.paginatoin-area {
    padding: 20px 0 18px;
    background-color: #fff;
}

.paginatoin-area .pagination-box li {
    display: inline-block;
}

.paginatoin-area .pagination-box li a {
    color: #fff;
    height: 40px;
    width: 40px;
    font-size: 14px;
    display: inline-block;
    padding: 0 10px;
    text-align: center;
    line-height: 40px;
    border-radius: 4px;
    background-color: #a8a8a8;
}

.paginatoin-area .pagination-box li a:hover {
    color: #fff;
    border-color: #24babc;
    background-color: #24babc;
}

.paginatoin-area .pagination-box li a.next {
    width: inherit;
}

.paginatoin-area .pagination-box li a.next i {
    padding-left: 5px;
}

.paginatoin-area .pagination-box li a.previous {
    width: inherit;
}

.paginatoin-area .pagination-box li a.previous i {
    padding-right: 5px;
}

.paginatoin-area .pagination-box li.active a {
    color: #fff;
    border-color: #24babc;
    background-color: #24babc;
}

/*------ pagination area style end ------*/
/*------ shop page style start ------*/
.shop-top-bar {
    padding: 15px;
    background-color: #fff;
}

.top-bar-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 767.98px) {
    .top-bar-left {
        padding-top: 15px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}

@media only screen and (max-width: 479.98px) {
    .top-bar-left {
        display: block;
        text-align: center;
    }
}

.top-bar-left .product-view-mode {
    margin-right: 60px;
}

@media only screen and (max-width: 479.98px) {
    .top-bar-left .product-view-mode {
        margin-right: 0;
    }
}

.top-bar-left .product-view-mode a {
    color: #b6b6b6;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
}

.top-bar-left .product-view-mode a i {
    font-size: 20px;
    margin-top: 6px;
}

.top-bar-left .product-view-mode a:hover {
    color: #24babc;
}

.top-bar-left .product-view-mode a.active {
    color: #24babc;
}

.top-bar-left .product-amount p {
    color: #777;
    font-size: 14px;
    text-transform: capitalize;
}

.top-bar-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@media only screen and (max-width: 767.98px) {
    .top-bar-right {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.top-bar-right .product-short {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.top-bar-right .product-short p {
    margin-right: 10px;
}

.nice-select {
    height: 36px;
    line-height: 34px;
    width: 200px;
    padding: 0 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .nice-select {
        width: 170px;
    }
}

@media only screen and (max-width: 479.98px) {
    .nice-select {
        width: 190px;
    }
}

.nice-select.open {
    border-color: #24babc;
}

.nice-select .list {
    right: 0;
    left: auto;
    width: 100%;
}

.nice-select .option {
    font-size: 13px;
    line-height: 34px;
    min-height: 34px;
    text-transform: capitalize;
}

.nice-select:after {
    height: 6px;
    width: 6px;
    border-bottom: 1px solid #999;
    border-right: 1px solid #999;
}

/*----- shop main wrapper end -----*/
/*----- product change view start -----*/
.shop-product-wrap {
    margin: 30px 0;
    padding: 15px 0 0;
    background-color: #fff;
}

.shop-product-wrap .product-item {
    margin-bottom: 15px;
}

.shop-product-wrap .col-md-4:last-child .product-list-item {
    margin-bottom: 0 !important;
}

.shop-product-wrap.list-view {
    padding: 15px 0;
}

.shop-product-wrap.list-view .product-item {
    display: none;
}

.shop-product-wrap.grid-view .product-item {
    display: block;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    padding: 0;
}

.shop-product-wrap.grid-view .product-list-item {
    display: none !important;
}

.shop-product-wrap.list-view .product-list-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-animation-name: fadeInRight;
    animation-name: fadeInRight;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
}

.shop-product-wrap.row.list-view .col-md-4 {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
}

/*----- product change view end -----*/
/*------ shop page style start ------*/
/*----- product details slider start ----*/
.pro-large-img {
    position: relative;
    padding: 1px;
}

.pro-large-img img {
    width: 100%;
    border: 1px solid #ebebeb;
}

.pro-nav-thumb {
    cursor: pointer;
    border: 1px solid #ebebeb;
}

/*----- product details slider end ----*/
/*----- product details content start ----*/
.product-details-inner {
    background-color: #fff;
    padding: 15px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-details-des {
        margin-top: 35px;
    }
}

.product-details-des .product-name {
    color: #252525;
    font-weight: 400;
    font-size: 24px;
    padding-bottom: 15px;
    display: block;
}

@media only screen and (max-width: 575.98px) {
    .product-details-des .product-name {
        font-size: 17px;
    }
}

.product-details-des .price-box .price-old,
.product-details-des .price-box .price-regular {
    font-size: 20px;
    font-weight: 500;
}

.product-details-des p {
    padding: 10px 0;
}

.product-details-des .pro-review span {
    font-size: 16px;
}

.product-details-des .customer-rev {
    padding-top: 10px;
}

.product-details-des .customer-rev a {
    color: #252525;
    font-size: 13px;
    text-transform: capitalize;
}

.product-details-des .customer-rev a:hover {
    color: #24babc;
}

.product-details-des .quantity {
    margin-right: 15px;
}

.product-details-des .quantity h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 700;
    line-height: 25px;
    margin-right: 10px;
    margin-bottom: 0;
}

@media only screen and (max-width: 479.98px) {
    .product-details-des .quantity h5 {
        margin-right: 10px;
    }
}

.product-details-des .quantity .pro-qty {
    width: 90px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 0 15px;
    border-radius: 4px;
    float: left;
}

.product-details-des .quantity .pro-qty .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 40px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #555;
}

.product-details-des .quantity .pro-qty input {
    width: 28px;
    float: left;
    border: none;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.product-details-des .availability i {
    color: #81ca33;
}

.product-details-des .availability span {
    color: #555;
    font-size: 13px;
    font-weight: 500;
    line-height: 1;
    padding-left: 3px;
    text-transform: uppercase;
}

.product-details-des .useful-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.product-details-des .useful-links a {
    color: #252525;
    margin-right: 10px;
    padding: 6px 15px;
    border: 1px solid #ebebeb;
    text-transform: capitalize;
    border-radius: 4px;
}

.product-details-des .useful-links a:hover {
    color: #fff;
    background-color: #24babc;
}

.product-details-des .useful-links a i {
    font-size: 14px;
    padding-right: 5px;
}

.product-details-des .like-icon {
    padding-top: 20px;
    border-top: 1px solid #ebebeb;
}

.product-details-des .like-icon a {
    color: #fff;
    text-align: center;
    display: inline-block;
    font-size: 12px;
    line-height: 22px;
    padding: 0 8px;
    margin-right: 6px;
    border-radius: 3px;
    text-transform: capitalize;
}

@media only screen and (max-width: 479.98px) {
    .product-details-des .like-icon a {
        margin-right: 0;
    }
}

.product-details-des .like-icon a i {
    padding-right: 5px;
}

.product-details-des .like-icon a.facebook {
    background-color: #3B5999;
}

.product-details-des .like-icon a.twitter {
    background-color: #1DA1F2;
}

.product-details-des .like-icon a.pinterest {
    background-color: #CB2028;
}

.product-details-des .like-icon a.google {
    background-color: #fe6d4c;
}

.product-details-des .like-icon a:hover.facebook {
    background-color: #2d4474;
}

.product-details-des .like-icon a:hover.twitter {
    background-color: #0c85d0;
}

.product-details-des .like-icon a:hover.pinterest {
    background-color: #9f191f;
}

.product-details-des .like-icon a:hover.google {
    background-color: #fe4419;
}

.product-details-des .share-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details-des .share-icon h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-right: 10px;
    text-transform: uppercase;
}

.product-details-des .share-icon a {
    color: #555;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    padding: 0 8px;
    margin-right: 5px;
}

@media only screen and (max-width: 479.98px) {
    .product-details-des .share-icon a {
        padding: 0 5px;
    }
}

.product-details-des .share-icon a:hover {
    color: #24babc;
}

.product-details-des .color-option {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details-des .color-option h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-right: 10px;
    text-transform: uppercase;
}

.product-details-des .color-option .nice-select {
    height: 40px;
    line-height: 40px;
}

.product-details-des .pro-size {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.product-details-des .pro-size h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
    margin-right: 10px;
    text-transform: uppercase;
}

.product-details-des .pro-size .nice-select {
    width: 100px;
    height: 40px;
    line-height: 40px;
}

.product-details-des .group-product-table {
    display: block;
}

.product-details-des .group-product-table.table-bordered {
    border: none;
}

.product-details-des .group-product-table td {
    width: 33.33%;
    padding: 8px;
    vertical-align: middle;
}

.product-details-des .group-product-table td a {
    color: #555;
    text-transform: capitalize;
}

.product-details-des .group-product-table td a:hover {
    color: #24babc;
}

.product-details-des .group-product-table td .pro-qty {
    width: 90px;
    height: 35px;
    border: 1px solid #ddd;
    padding: 0 15px;
    float: left;
}

.product-details-des .group-product-table td .pro-qty .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 31px;
    cursor: pointer;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
    color: #555;
}

.product-details-des .group-product-table td .pro-qty input {
    width: 28px;
    float: left;
    border: none;
    height: 33px;
    line-height: 33px;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.product-details-des.box-layout {
    padding-top: 30px;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-details-des.box-layout {
        padding-top: 0;
    }
}

.product-details-des.box-layout p {
    max-width: 80%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-details-des.box-layout p {
        max-width: 100%;
        padding: 0 15px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .product-details-des.is-stickyy {
        margin-top: 0;
    }
}

.product-details-des.quick-des p {
    padding-top: 0;
}

/*----- product details content end ----*/
/*----- reviews area start -----*/
.product-details-reviews {
    background-color: #fff;
    padding: 15px;
}

.product-review-info .nav.review-tab li a {
    color: #fff;
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    text-transform: uppercase;
    padding: 8px 10px;
    border: 1px solid #ebebeb;
    border-bottom: none;
    background-color: #979898;
}

.product-review-info .nav.review-tab li a:hover,
.product-review-info .nav.review-tab li a.active {
    color: #555;
    background-color: #fff;
}

@media only screen and (max-width: 479.98px) {
    .product-review-info .nav.review-tab li a {
        font-size: 12px;
        padding: 8px 4px;
    }
}

.product-review-info .tab-content.reviews-tab {
    border: 1px solid #ebebeb;
    padding: 15px 20px 20px;
}

.product-review-info .tab-content.reviews-tab .table {
    margin-bottom: 0;
}

.product-review-info .tab-content.reviews-tab .table tr {
    text-transform: capitalize;
}

.product-review-info .tab-content .review-description {
    padding-top: 25px;
    padding-bottom: 25px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .product-review-info .tab-content .review-description {
        padding: 15px 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .product-review-info .tab-content .review-description {
        display: block;
    }
}

.product-review-info .tab-content .review-description .tab-thumb {
    -webkit-flex-basis: 300px;
    -ms-flex-preferred-size: 300px;
    flex-basis: 300px;
}

@media only screen and (max-width: 767.98px) {
    .product-review-info .tab-content .review-description .tab-thumb {
        -webkit-flex-basis: 220px;
        -ms-flex-preferred-size: 220px;
        flex-basis: 220px;
    }
}

.product-review-info .tab-content .review-description .tab-des {
    padding-left: 40px;
    -webkit-flex-basis: calc(100% - 300px);
    -ms-flex-preferred-size: calc(100% - 300px);
    flex-basis: calc(100% - 300px);
}

@media only screen and (max-width: 767.98px) {
    .product-review-info .tab-content .review-description .tab-des {
        padding-left: 14px;
        -webkit-flex-basis: calc(100% - 220px);
        -ms-flex-preferred-size: calc(100% - 220px);
        flex-basis: calc(100% - 220px);
    }
}

.product-review-info .tab-content .review-description .tab-des h3 {
    color: #24babc;
    font-size: 20px;
    font-weight: 22px;
    font-weight: 700;
    text-transform: capitalize;
    padding-bottom: 10px;
}

.product-review-info .tab-content .review-description .tab-des ul li {
    font-size: 13px;
    list-style: inherit;
}

.review-form h5 {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 20px;
    text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
    .review-form h5 {
        font-size: 16px;
    }
}

.total-reviews {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 50px;
}

.total-reviews .rev-avatar {
    -webkit-flex-basis: 60px;
    -ms-flex-preferred-size: 60px;
    flex-basis: 60px;
    max-height: 60px;
}

.total-reviews .review-box {
    margin-left: 10px;
    border: 1px solid #ebebeb;
    padding: 10px;
    -webkit-flex-basis: calc(100% - 70px);
    -ms-flex-preferred-size: calc(100% - 70px);
    flex-basis: calc(100% - 70px);
}

.total-reviews .review-box .post-author {
    padding-bottom: 10px;
}

.total-reviews .review-box .post-author p {
    font-size: 12px;
    font-style: italic;
    text-transform: capitalize;
}

.total-reviews .review-box .post-author p span {
    font-size: 13px;
}

.total-reviews .review-box p {
    font-size: 14px;
    font-style: italic;
}

/*----- reviews area end -----*/
/* Checkout Login Coupon Accordion Start */
.checkout-page-wrapper {
    padding-top: 50px;
    padding-bottom: 42px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .checkout-page-wrapper {
        padding-bottom: 50px;
    }
}

.checkoutaccordion h3 {
    background-color: #f7f7f7;
    border-top: 3px solid #24babc;
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    position: relative;
    text-transform: uppercase;
}

.checkoutaccordion h3 span {
    color: #24babc;
    cursor: pointer;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    margin-left: 10px;
}

@media only screen and (max-width: 479.98px) {
    .checkoutaccordion h3 span {
        display: block;
        padding-top: 5px;
        margin-left: 0;
    }
}

.checkoutaccordion h3 span:hover {
    color: #252525;
}

@media only screen and (max-width: 479.98px) {
    .checkoutaccordion h3 {
        font-size: 14px;
    }
}

.checkoutaccordion .card {
    border: none;
    padding: 0;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    margin-bottom: 30px;
}

.checkoutaccordion .card:last-child {
    margin-bottom: 0;
}

.checkoutaccordion .card .card-body {
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 20px;
}

.checkoutaccordion .card .card-body .cart-update-option {
    border: none;
    padding: 0;
}

.checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
    padding: 12px 10px;
    background-color: #f7f7f7;
    border: 1px solid #ccc;
    margin-right: 15px;
}

@media only screen and (max-width: 479.98px) {
    .checkoutaccordion .card .card-body .cart-update-option .apply-coupon-wrapper input {
        margin-bottom: 15px;
    }
}

.checkout-box-wrap .custom-control {
    padding-left: 0;
    margin-left: 1.5rem;
}

.checkout-billing-details-wrap h2 {
    color: #252525;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-weight: 500;
}

@media only screen and (max-width: 767.98px) {
    .checkout-billing-details-wrap h2 {
        margin-bottom: 26px;
    }
}

.checkout-billing-details-wrap .billing-form-wrap {
    margin-top: -20px;
}

.forget-pwd {
    color: #24babc;
    font-size: 12px;
    margin-top: -6px;
}

.single-form-row {
    margin-top: 15px;
    display: none;
}

.single-form-row p {
    margin: 0;
    font-size: 14px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .order-summary-details {
        margin-top: 30px;
    }
}

.order-summary-details h2 {
    color: #252525;
    font-size: 18px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 15px;
    margin-bottom: 30px;
    font-weight: 500;
}

.order-summary-table {
    background-color: #f7f7f7;
    margin-top: 34px;
}

.order-summary-table .table,
.order-summary-table table {
    color: #252525;
    margin-bottom: 0;
    font-size: 15px;
    white-space: nowrap;
}

.order-summary-table .table tr td,
.order-summary-table .table tr th,
.order-summary-table table tr td,
.order-summary-table table tr th {
    font-weight: 400;
    vertical-align: middle;
    padding: 15px 10px;
    border-width: 1px;
}

.order-summary-table .table tr td a,
.order-summary-table .table tr th a,
.order-summary-table table tr td a,
.order-summary-table table tr th a {
    color: #252525;
    font-weight: 400;
}

.order-summary-table .table tr td strong,
.order-summary-table .table tr th strong,
.order-summary-table table tr td strong,
.order-summary-table table tr th strong {
    font-weight: 400;
}

.order-summary-table .shipping-type {
    text-align: left;
}

.order-summary-table .shipping-type li {
    margin-bottom: 5px;
}

.order-summary-table .shipping-type li:last-child {
    margin-bottom: 0;
}

.order-payment-method {
    background-color: #f7f7f7;
    padding: 40px 20px 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .order-payment-method {
        padding: 32px 20px 36px;
    }
}

.single-payment-method {
    margin-bottom: 20px;
}

.single-payment-method:last-child {
    margin-bottom: 0;
}

.single-payment-method:first-child .payment-method-details {
    display: block;
}

.single-payment-method label,
.single-payment-method .custom-control-label {
    font-weight: 500;
}

.single-payment-method .paypal-card {
    max-width: 150px;
    height: 50px;
    margin-top: 10px;
    display: block;
}

.single-payment-method .payment-method-details {
    background-color: #f1f1f1;
    color: #252525;
    font-weight: 400;
    font-size: 13px;
    padding: 10px;
    position: relative;
    margin-top: 20px;
    display: none;
}

.single-payment-method .payment-method-details:after {
    content: '';
    position: absolute;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #f1f1f1;
    bottom: 100%;
}

.single-payment-method .payment-method-details * {
    margin: 0;
}

.summary-footer-area .btn-brand {
    margin-top: 40px;
}

/*------- input box style css start -------*/
.single-input-item {
    margin-top: 20px;
}

.single-input-item label {
    color: #252525;
    text-transform: capitalize;
    font-size: 14px;
}

.single-input-item label.required:after {
    content: '*';
    color: red;
    font-size: 14px;
    margin-left: 3px;
    margin-top: 5px;
}

.single-input-item input,
.single-input-item textarea {
    color: #555;
    border: 1px solid #ccc;
    padding: 12px 10px;
    width: 100%;
    font-size: 14px;
    background: #f7f7f7;
}

.single-input-item input:active,
.single-input-item input:focus,
.single-input-item textarea:active,
.single-input-item textarea:focus {
    border-color: #24babc;
    background-color: #fff;
}

.single-input-item .nice-select {
    width: 100%;
    border-radius: 0;
    height: 48px;
    border-color: #ccc;
    background-color: #f7f7f7;
}

.single-input-item .nice-select .current {
    color: #555;
}

.single-input-item .nice-select .list {
    max-height: 200px;
    overflow: auto;
    width: 100%;
}

/*------- input box style css end -------*/
/*-------- Custom Checkbox Style start --------*/
.custom-checkbox.custom-control,
.custom-radio.custom-control {
    min-height: auto;
}

.custom-checkbox label,
.custom-checkbox .custom-control-label,
.custom-radio label,
.custom-radio .custom-control-label {
    color: #252525;
    font-size: 14px;
    line-height: 1;
    padding-left: 10px;
    font-weight: 400;
    display: block;
    cursor: pointer;
}

.custom-checkbox label:before,
.custom-checkbox .custom-control-label:before,
.custom-radio label:before,
.custom-radio .custom-control-label:before {
    background-color: #ddd;
    border: 1px solid #ddd;
    border-radius: 0;
    height: 15px;
    top: 0;
    width: 15px;
}

.custom-checkbox label:after,
.custom-checkbox .custom-control-label:after,
.custom-radio label:after,
.custom-radio .custom-control-label:after {
    background-size: 10px 10px;
    height: 15px;
    top: 0;
}

.custom-checkbox label a,
.custom-checkbox .custom-control-label a,
.custom-radio label a,
.custom-radio .custom-control-label a {
    color: #252525;
    font-weight: 500;
}

.custom-checkbox label a:hover,
.custom-checkbox .custom-control-label a:hover,
.custom-radio label a:hover,
.custom-radio .custom-control-label a:hover {
    color: #24babc;
}

.custom-checkbox input,
.custom-checkbox .custom-control-input,
.custom-radio input,
.custom-radio .custom-control-input {
    outline: none;
}

.custom-checkbox input:focus~.custom-control-label:before,
.custom-checkbox input:focus label:before,
.custom-checkbox .custom-control-input:focus~.custom-control-label:before,
.custom-checkbox .custom-control-input:focus label:before,
.custom-radio input:focus~.custom-control-label:before,
.custom-radio input:focus label:before,
.custom-radio .custom-control-input:focus~.custom-control-label:before,
.custom-radio .custom-control-input:focus label:before {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.custom-checkbox input:checked~.custom-control-label:before,
.custom-checkbox input:checked label:before,
.custom-checkbox .custom-control-input:checked~.custom-control-label:before,
.custom-checkbox .custom-control-input:checked label:before,
.custom-radio input:checked~.custom-control-label:before,
.custom-radio input:checked label:before,
.custom-radio .custom-control-input:checked~.custom-control-label:before,
.custom-radio .custom-control-input:checked label:before {
    background-color: #24babc;
    border-color: #24babc;
}

/*-------- Custom Checkbox Style end --------*/
/*------- Custom Radio Button Style start -------*/
.custom-radio label:before,
.custom-radio .custom-control-label:before {
    border-radius: 50%;
    height: 14px;
    width: 14px;
}

.custom-radio label::after,
.custom-radio .custom-control-label::after {
    background-size: 10px 10px;
    left: -22px;
    top: 2px;
    height: 10px;
    width: 10px;
}

/*------- Custom Radio Button Style end -------*/
/*--------- Cart Page Wrapper Start ---------*/
.cart-table .table {
    margin: 0;
    white-space: nowrap;
}

.cart-table .table thead {
    background-color: #24babc;
}

.cart-table .table thead tr th {
    border-color: #24babc;
    border-bottom: 0 solid transparent;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    padding: 15px 20px;
    text-align: center;
    text-transform: uppercase;
}

.cart-table .table tbody tr td {
    border-color: #ccc;
    text-align: center;
    padding: 10px 20px;
    vertical-align: middle;
    font-weight: 500;
    color: #252525;
}

.cart-table .table tbody tr td a {
    color: #252525;
    font-weight: 400;
    text-transform: capitalize;
}

.cart-table .table tbody tr td a:hover {
    color: #24babc;
}

.cart-table .table tbody tr td a.btn {
    color: #fff;
}

.cart-table .table tbody tr td a.btn:hover {
    color: #fff;
}

.cart-table .table tbody tr td a.check-btn {
    color: #fff;
}

.cart-table .table tr .pro-thumbnail,
.cart-table .table tr .pro-price,
.cart-table .table tr .pro-quantity,
.cart-table .table tr .pro-subtotal,
.cart-table .table tr .pro-remove {
    width: 140px;
}

.cart-table .table tr .pro-qty {
    width: 90px;
    height: 40px;
    border: 1px solid #ddd;
    padding: 0 15px;
    float: left;
}

.cart-table .table tr .pro-qty .qtybtn {
    width: 15px;
    display: block;
    float: left;
    line-height: 38px;
    cursor: pointer;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #555;
}

.cart-table .table tr .pro-qty input {
    width: 28px;
    float: left;
    border: none;
    height: 40px;
    line-height: 34px;
    padding: 0;
    text-align: center;
    background-color: transparent;
}

.cart-update-option {
    border: 1px solid #ccc;
    margin-top: 15px;
    padding: 15px 20px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@media only screen and (min-width: 1200px),
only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .cart-update-option {
        margin-top: 0;
        border-top: 0 solid transparent;
    }
}

.cart-update-option .apply-coupon-wrapper {
    width: 55%;
}

@media only screen and (max-width: 767.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .cart-update-option .apply-coupon-wrapper {
        width: auto;
    }
}

.cart-update-option .apply-coupon-wrapper form {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    white-space: nowrap;
}

.cart-update-option .apply-coupon-wrapper form input {
    border: 1px solid #ccc;
    font-size: 14px;
    padding: 9px 10px;
    outline: none;
    margin-right: 15px;
    width: 100%;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.cart-update-option .apply-coupon-wrapper form input:focus,
.cart-update-option .apply-coupon-wrapper form input:active {
    border-color: #24babc;
}

@media only screen and (max-width: 767.98px) {
    .cart-update-option .apply-coupon-wrapper button {
        display: block;
        width: 100%;
        margin-top: 15px;
        border-radius: 0;
    }
}

@media only screen and (max-width: 767.98px) {
    .cart-update-option .cart-update {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 767.98px) {
    .cart-update-option .cart-update .btn {
        width: 100%;
    }
}

.cart-calculator-wrapper {
    margin-top: 30px;
    background-color: #f8f8f8;
}

.cart-calculator-wrapper h3 {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
    padding: 27px 15px 25px;
    margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items {
    font-weight: 500;
}

.cart-calculator-wrapper .cart-calculate-items .table {
    margin-bottom: 0;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td {
    color: #252525;
    padding: 15px 20px;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td:nth-child(2) {
    color: #252525;
    text-align: right;
}

.cart-calculator-wrapper .cart-calculate-items .table tr td.total-amount {
    color: #24babc;
    font-weight: 700;
}

.cart-calculator-wrapper a {
    border-radius: 0;
    text-align: center;
}

/*--------- Cart Page Wrapper end ---------*/
/*------ Start Compare Page Wrapper -----*/
.compare-table .table tbody tr td {
    text-align: center;
    padding: 20px 20px;
    vertical-align: middle;
    border-color: #ccc;
}

.compare-table .table tbody tr td.first-column {
    min-width: 175px;
    font-size: 15px;
    font-weight: 500;
    color: #252525;
    margin: 0;
}

@media only screen and (max-width: 767.98px) {
    .compare-table .table tbody tr td.first-column {
        min-width: 115px;
    }
}

.compare-table .table tbody tr td.product-image-title {
    min-width: 310px;
    vertical-align: bottom;
}

@media only screen and (max-width: 767.98px) {
    .compare-table .table tbody tr td.product-image-title {
        min-width: 220px;
    }
}

.compare-table .table tbody tr td.product-image-title .image {
    clear: both;
    width: 100%;
    margin-bottom: 15px;
    display: block;
}

.compare-table .table tbody tr td.product-image-title .category {
    float: left;
    clear: both;
    color: #24babc;
    text-transform: capitalize;
    letter-spacing: 0.5px;
}

.compare-table .table tbody tr td.product-image-title .title {
    float: left;
    clear: both;
    font-size: 16px;
    color: #252525;
    font-weight: 500;
    text-transform: capitalize;
}

.compare-table .table tbody tr td.product-image-title .title:hover {
    color: #24babc;
}

.compare-table .table tbody tr td.pro-desc p {
    text-align: left;
    margin: 0;
}

.compare-table .table tbody tr td.pro-price,
.compare-table .table tbody tr td.pro-color,
.compare-table .table tbody tr td.pro-stock {
    font-size: 14px;
    font-weight: 400;
}

.compare-table .table tbody tr td.pro-remove button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.compare-table .table tbody tr td.pro-remove button:hover {
    color: #e74c3c;
}

.compare-table .table tbody tr td.pro-remove button i {
    font-size: 20px;
}

.compare-table .table tbody tr td.pro-ratting i {
    color: #24babc;
}

/*------ end Compare Page Wrapper -----*/
/*------- Start About Page Wrapper --------*/
.about-us-wrapper {
    padding: 70px 0;
}

@media only screen and (max-width: 767.98px) {
    .about-us-wrapper {
        padding: 47px 0 50px;
    }
}

.about-title {
    text-align: center;
    padding-bottom: 50px;
}

.about-title h2 {
    font-size: 24px;
    color: #252525;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    display: inline-block;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
    position: relative;
}

@media only screen and (max-width: 479.98px) {
    .about-title h2 {
        font-size: 22px;
    }
}

.about-title h2:before {
    bottom: -18px;
    left: 0;
    right: 0;
    width: 50%;
    height: 2px;
    content: "";
    margin: auto;
    position: absolute;
    background-color: #24babc;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .about-text-wrap {
        padding-bottom: 30px;
    }
}

.about-text-wrap h2 {
    color: #252525;
    font-size: 24px;
    line-height: 1.2;
    font-weight: 500;
    margin-bottom: 15px;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
}

@media only screen and (max-width: 767.98px) {
    .about-text-wrap h2 {
        font-size: 24px;
        margin-bottom: 8px;
    }
}

@media only screen and (max-width: 575.98px) {
    .about-text-wrap h2 {
        font-size: 22px;
    }
}

.about-text-wrap h2 span {
    color: #555;
    display: block;
    font-weight: 400;
    font-size: 16px;
    padding-bottom: 5px;
    line-height: 24px;
    margin-top: -4px;
}

.about-text-wrap p {
    margin-bottom: 10px;
}

.about-text-wrap p:last-child {
    margin-bottom: 0;
}

.about-image-wrap {
    position: relative;
    padding-left: 30px;
    padding-top: 30px;
    z-index: 1;
}

@media only screen and (max-width: 575.98px) {
    .about-image-wrap {
        padding-left: 15px;
        padding-top: 15px;
    }
}

.about-image-wrap img {
    width: 100%;
}

.about-image-wrap:after {
    border: 5px solid #24babc;
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: calc(100% - 30px);
    width: calc(100% - 30px);
    z-index: -1;
}

/*------- end About Page Wrapper --------*/
/*------- team area start -------*/
.team-area {
    padding-top: 67px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 767.98px) {
    .team-area {
        padding: 45px 0 5px;
    }
}

.team-member {
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.team-member .team-thumb {
    position: relative;
    text-align: center;
    overflow: hidden;
}

.team-member .team-thumb img {
    width: 100%;
}

.team-member .team-thumb .team-social {
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    background-color: #fff;
    -webkit-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
    opacity: 0;
    visibility: hidden;
}

.team-member .team-thumb .team-social a {
    color: #252525;
    width: 30px;
    height: 30px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    line-height: 30px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-right: 5px;
}

.team-member .team-thumb .team-social a:hover {
    color: #fff;
    background-color: #24babc;
    border-color: #24babc;
}

.team-member .team-content {
    padding: 5px;
}

.team-member .team-content h3 {
    color: #252525;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    padding-top: 15px;
    text-transform: capitalize;
}

.team-member .team-content h6 {
    font-size: 14px;
    color: #24babc;
    line-height: 20px;
    text-transform: capitalize;
}

.team-member .team-content p {
    padding-top: 10px;
}

.team-member:hover .team-social {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
}

.team-member:hover .team-thumb img {
    opacity: 0.5;
}

/*------- team area end -------*/
/*-------- choosing area start --------*/
.choosing-area {
    padding-top: 65px;
    padding-bottom: 32px;
}

@media only screen and (max-width: 767.98px) {
    .choosing-area {
        padding: 45px 0 10px;
    }
}

.single-choose-item i {
    color: #969ca7;
    font-size: 44px;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.single-choose-item h4 {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
    padding: 15px 0 10px;
    text-transform: uppercase;
}

.single-choose-item:hover i {
    color: #24babc;
}

/*-------- choosing area end --------*/
/*------ contact form area start -------*/
.contact-area {
    padding: 67px 0 70px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .contact-area {
        padding: 67px 0 63px;
    }
}

@media only screen and (max-width: 767.98px) {
    .contact-area {
        padding: 47px 0 42px;
    }
}

.contact-message h2 {
    color: #252525;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 28px;
    text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
    .contact-message h2 {
        font-size: 18px;
        padding-bottom: 15px;
    }
}

.contact-message form input,
.contact-message form textarea {
    width: 100%;
    border: none;
    padding: 10px 10px;
    border-bottom: 3px solid transparent;
    background-color: #f7f7f7;
    margin-bottom: 30px;
}

.contact-message form textarea {
    height: 150px;
}

/*------ contact form area start -------*/
/*------ contact info area start -------*/
.contact-info {
    height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .contact-info {
        margin-top: 46px;
    }
}

.contact-info h2 {
    color: #252525;
    font-size: 22px;
    font-weight: 500;
    line-height: 1;
    padding-bottom: 22px;
    text-transform: capitalize;
}

@media only screen and (max-width: 575.98px) {
    .contact-info h2 {
        font-size: 18px;
        padding-bottom: 8px;
    }
}

.contact-info p {
    color: #555;
    padding-bottom: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .contact-info p {
        padding-bottom: 10px;
    }
}

.contact-info ul li {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
    margin-bottom: 15px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

@media only screen and (max-width: 479.98px) {
    .contact-info ul li {
        -webkit-box-align: baseline;
        -webkit-align-items: baseline;
        -ms-flex-align: baseline;
        align-items: baseline;
    }
}

.contact-info ul li:last-child {
    border-bottom: none;
}

.contact-info ul li i {
    font-size: 18px;
    padding-right: 10px;
}

.contact-info .working-time h3 {
    color: #252525;
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 5px;
}

.contact-info .working-time p {
    padding-bottom: 0;
}

.contact-info .working-time p span {
    color: #252525;
    padding-right: 10px;
}

/*------ contact info area end -------*/
/*-------- Start My Account Page Wrapper --------*/
.myaccount-tab-menu {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.myaccount-tab-menu a {
    border: 1px solid #ccc;
    border-bottom: none;
    color: #252525;
    font-weight: 400;
    font-size: 15px;
    display: block;
    padding: 10px 15px;
    text-transform: uppercase;
}

.myaccount-tab-menu a:last-child {
    border-bottom: 1px solid #ccc;
}

.myaccount-tab-menu a:hover,
.myaccount-tab-menu a.active {
    background-color: #24babc;
    border-color: #24babc;
    color: #fff;
}

.myaccount-tab-menu a i.fa {
    font-size: 14px;
    text-align: center;
    width: 25px;
}

@media only screen and (max-width: 767.98px) {
    #myaccountContent {
        margin-top: 30px;
    }
}

.myaccount-content {
    border: 1px solid #eeeeee;
    padding: 30px;
}

@media only screen and (max-width: 575.98px) {
    .myaccount-content {
        padding: 20px 15px;
    }
}

.myaccount-content form {
    margin-top: -20px;
}

.myaccount-content h3 {
    font-size: 20px;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 10px;
    margin-bottom: 25px;
    font-weight: 400;
}

.myaccount-content .welcome a {
    color: #252525;
}

.myaccount-content .welcome a:hover {
    color: #24babc;
}

.myaccount-content .welcome strong {
    font-weight: 500;
    color: #24babc;
}

.myaccount-content fieldset {
    margin-top: 20px;
}

.myaccount-content fieldset legend {
    color: #252525;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 400;
    border-bottom: 1px dashed #ccc;
}

.myaccount-table {
    white-space: nowrap;
    font-size: 14px;
}

.myaccount-table table th,
.myaccount-table .table th {
    color: #252525;
    padding: 10px;
    font-weight: 400;
    background-color: #f8f8f8;
    border-color: #ccc;
    border-bottom: 0;
}

.myaccount-table table td,
.myaccount-table .table td {
    padding: 10px;
    vertical-align: middle;
    border-color: #ccc;
}

.saved-message {
    background-color: #f4f5f7;
    border-top: 3px solid #24babc;
    border-radius: 5px 5px 0 0;
    font-weight: 400;
    font-size: 15px;
    color: #555;
    padding: 20px;
}

/*-------- Start My Account Page Wrapper --------*/
/*------ Start Login & Register Page ------*/
.login-reg-form-wrap {
    background-color: #fff;
    -webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    padding: 23px 30px 30px;
}

@media only screen and (max-width: 575.98px) {
    .login-reg-form-wrap {
        padding: 23px 15px 30px;
    }
}

.login-reg-form-wrap h2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 1;
    text-transform: capitalize;
    color: #252525;
    border-bottom: #ccc;
    margin-bottom: 30px;
}

.login-reg-form-wrap form .create-account {
    margin-top: 25px;
}

.login-reg-form-wrap .login-reg-form-meta a {
    color: #24babc;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .sign-up-form {
        margin-top: 50px;
    }
}

/*------ end Login & Register Page ------*/
/*----- blog sidebar start -------*/
@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .blog-sidebar-wrapper {
        margin-top: 50px;
    }
}

.blog-sidebar-wrapper .blog-sidebar:last-child {
    margin-bottom: 0;
}

.blog-sidebar {
    margin-bottom: 35px;
    background-color: #fff;
    padding: 15px;
}

.blog-sidebar .title {
    color: #252525;
    font-size: 20px;
    line-height: 1;
    margin-top: -2px;
    font-weight: 400;
    margin-bottom: 40px;
    position: relative;
    text-transform: capitalize;
    font-family: "Rubik", sans-serif;
}

@media only screen and (max-width: 479.98px) {
    .blog-sidebar .title {
        font-size: 18px;
    }
}

.blog-sidebar .title:before {
    width: 100%;
    height: 1px;
    bottom: -10px;
    left: 0;
    background-color: #ebebeb;
    content: "";
    position: absolute;
}

.blog-sidebar .search-field {
    width: calc(100% - 50px);
    border: 1px solid #ccc;
    padding: 0 10px;
    color: #555;
    font-size: 14px;
    height: 50px;
    float: left;
    text-transform: capitalize;
}

.blog-sidebar .search-btn {
    width: 50px;
    height: 50px;
    margin-left: -4px;
    text-align: center;
    cursor: pointer;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.blog-sidebar .search-btn i {
    font-size: 20px;
    line-height: 50px;
    color: #252525;
    -webkit-transition: 0.4s;
    -o-transition: 0.4s;
    transition: 0.4s;
}

.blog-sidebar .search-btn:hover {
    background-color: #24babc;
    border-color: #24babc;
}

.blog-sidebar .search-btn:hover i {
    color: #fff;
}

.blog-sidebar .blog-archive {
    margin-top: -10px;
}

.blog-sidebar .blog-archive li a {
    color: #555;
    font-size: 14px;
    line-height: 1;
    text-transform: capitalize;
    padding: 13px 0;
    display: inline-block;
}

.blog-sidebar .blog-archive li a:hover {
    color: #24babc;
}

.blog-sidebar .blog-archive li:last-child a {
    padding-bottom: 0;
}

.blog-sidebar .blog-archive li:first-child a {
    padding-top: 0;
}

.blog-sidebar .blog-tags li {
    display: inline-block;
    margin-bottom: 5px;
}

.blog-sidebar .blog-tags li a {
    color: #252525;
    font-size: 13px;
    border: 1px solid #ccc;
    padding: 6px 15px 4px;
    display: inline-block;
    text-transform: uppercase;
}

.blog-sidebar .blog-tags li a:hover {
    color: #fff;
    background-color: #24babc;
    border-color: #24babc;
}

/*----- blog sidebar end -------*/
.blog-category {
    margin-top: -10px;
}

/*------ recent post start ------*/
.recent-post .recent-post-item:last-child {
    margin-bottom: 0;
}

.recent-post-item {
    margin-bottom: 30px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.recent-post-item .product-thumb {
    max-width: 70px;
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 70px;
    -ms-flex: 1 0 70px;
    flex: 1 0 70px;
}

.recent-post-item .recent-post-description {
    padding-left: 10px;
}

.recent-post-item .recent-post-description h4 {
    font-size: 15px;
    line-height: 1.3;
    font-weight: 400;
    padding-bottom: 10px;
    text-transform: capitalize;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
    .recent-post-item .recent-post-description h4 {
        padding-bottom: 5px;
    }
}

.recent-post-item .recent-post-description h4 a {
    color: #252525;
}

.recent-post-item .recent-post-description h4 a:hover {
    color: #24babc;
}

.recent-post-item .recent-post-description p {
    text-transform: capitalize;
}

/*------ recent post end ------*/
/*------- blog item start -------*/
.blog-item-wrapper {
    background-color: #fff;
    padding: 15px;
}

.blog-item-wrapper .paginatoin-area {
    background-color: #f2f2f2;
}

.blog-post-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

@media only screen and (max-width: 767.98px) {
    .blog-post-item {
        display: block;
    }
}

.blog-post-item.blog-grid {
    display: block;
}

.blog-post-thumb {
    -webkit-flex-basis: 360px;
    -ms-flex-preferred-size: 360px;
    flex-basis: 360px;
    max-width: 360px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .blog-post-thumb {
        -webkit-flex-basis: 280px;
        -ms-flex-preferred-size: 280px;
        flex-basis: 280px;
        max-width: 280px;
    }
}

@media only screen and (max-width: 767.98px) {
    .blog-post-thumb {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
}

.post-info-wrapper {
    padding-left: 30px;
    -webkit-flex-basis: calc(100% - 360px);
    -ms-flex-preferred-size: calc(100% - 360px);
    flex-basis: calc(100% - 360px);
    max-width: calc(100% - 360px);
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px) {
    .post-info-wrapper {
        -webkit-flex-basis: calc(100% - 280px);
        -ms-flex-preferred-size: calc(100% - 280px);
        flex-basis: calc(100% - 280px);
        max-width: calc(100% - 280px);
    }
}

@media only screen and (max-width: 767.98px) {
    .post-info-wrapper {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 100%;
        padding-left: 0;
        margin-top: 30px;
    }
}

.entry-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .entry-header {
        margin-bottom: 15px;
    }
}

.entry-header .post-date {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 5px 20px;
    background-color: #2d70b1;
    width: 60px;
    height: 55px;
}

.entry-header .post-date .date {
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    line-height: 1;
    display: block;
    padding-bottom: 5px;
}

.entry-header .post-date .month {
    color: #fff;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.2;
    display: block;
    text-transform: uppercase;
}

.entry-header .post-meta {
    padding-left: 10px;
}

.entry-header .post-meta h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    text-transform: uppercase;
    font-family: "Rubik", sans-serif;
}

@media only screen and (max-width: 767.98px) {
    .entry-header .post-meta h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 575.98px) {
    .entry-header .post-meta h2 {
        font-size: 16px;
    }
}

.entry-header .post-meta h2 a {
    color: #252525;
}

.entry-header .post-meta h2 a:hover {
    color: #24babc;
}

.entry-header .post-meta .post-meta-small a {
    color: #24babc;
    font-style: italic;
}

.entry-header .post-meta.blog-details-title h2 {
    font-size: 24px;
}

@media only screen and (max-width: 767.98px) {
    .entry-header .post-meta.blog-details-title h2 {
        font-size: 18px;
    }
}

@media only screen and (max-width: 575.98px) {
    .entry-header .post-meta.blog-details-title h2 {
        font-size: 16px;
    }
}

.entry-summary {
    margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199.98px),
only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .entry-summary {
        margin-bottom: 15px;
    }
}

blockquote {
    border-left: 5px solid #24babc;
    margin: 25px 32px 25px 30px;
    background-color: #f7f7f7;
    padding: 15px;
}

blockquote p {
    margin-top: 0 !important;
}

@media only screen and (max-width: 767.98px) {
    blockquote {
        margin: 25px 10px 25px 15px;
    }
}

/*------- blog item end -------*/
/*-------- blog grid item start --------*/
.blog-grid {
    display: block;
}

.blog-grid .blog-post-thumb {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.blog-grid .post-info-wrapper {
    max-width: 100%;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding-left: 0;
    padding-top: 30px;
}

@media only screen and (max-width: 767.98px) {
    .blog-grid .post-info-wrapper {
        padding-top: 0;
    }
}

/*-------- blog grid item end --------*/
/*------- blog main content wrapper start --------*/
.blog-post-item .tag-line {
    padding: 10px 0;
    margin-top: 20px;
    border-top: 1px solid #ebebeb;
    border-bottom: 1px solid #ebebeb;
}

.blog-post-item .tag-line h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    padding-right: 5px;
}

.blog-post-item .tag-line a {
    color: #252525;
    font-style: italic;
    font-size: 14px;
    text-transform: capitalize;
}

.blog-post-item .tag-line a:hover {
    color: #24babc;
}

.blog-share-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    padding-top: 30px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .blog-share-link {
        padding-top: 15px;
    }
}

.blog-share-link h5 {
    font-size: 14px;
    font-weight: 500;
    color: #252525;
    padding-right: 15px;
    text-transform: uppercase;
}

.blog-share-link .blog-social-icon a {
    width: 36px;
    height: 36px;
    font-size: 15px;
    line-height: 36px;
    text-align: center;
    display: inline-block;
    color: #fff;
    border-radius: 50%;
    margin-right: 5px;
}

.blog-share-link .blog-social-icon a.facebook {
    background-color: #3B5999;
}

.blog-share-link .blog-social-icon a.twitter {
    background-color: #1DA1F2;
}

.blog-share-link .blog-social-icon a.pinterest {
    background-color: #CB2028;
}

.blog-share-link .blog-social-icon a.google {
    background-color: #fe6d4c;
}

.blog-share-link .blog-social-icon a:hover.facebook {
    background-color: #2d4474;
}

.blog-share-link .blog-social-icon a:hover.twitter {
    background-color: #0c85d0;
}

.blog-share-link .blog-social-icon a:hover.pinterest {
    background-color: #9f191f;
}

.blog-share-link .blog-social-icon a:hover.google {
    background-color: #fe4419;
}

@media only screen and (max-width: 479.98px) {
    .blog-share-link .blog-social-icon a {
        width: 30px;
        height: 30px;
        line-height: 30px;
        font-size: 13px;
        margin-right: 0;
    }
}

/*--- blog main content wrapper end ---*/
/*--- blog comment section start ---*/
.comment-section h3 {
    color: #252525;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    padding-bottom: 15px;
    text-transform: uppercase;
}

.comment-section ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px;
    margin-bottom: 30px;
    border: 1px solid #ebebeb;
}

.comment-section ul li:last-child {
    margin-bottom: 5px;
}

@media only screen and (max-width: 479.98px) {
    .comment-section ul li {
        display: block;
    }
}

.comment-section ul li .author-avatar {
    -webkit-flex-basis: 66px;
    -ms-flex-preferred-size: 66px;
    flex-basis: 66px;
    max-height: 62px;
    margin-right: 10px;
}

@media only screen and (max-width: 479.98px) {
    .comment-section ul li .author-avatar {
        -webkit-flex-basis: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        max-width: 66px;
        height: 62px;
    }
}

.comment-section ul li.comment-children {
    margin-left: 40px;
}

.comment-section ul li .comment-body {
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
}

.comment-section ul li .comment-body h5 {
    color: #252525;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
    text-transform: capitalize;
}

.comment-section ul li .comment-body .comment-post-date {
    color: #555;
    padding-bottom: 10px;
}

.comment-section ul li .comment-body .reply-btn {
    float: right;
}

.comment-section ul li .comment-body .reply-btn a {
    color: #252525;
    font-size: 12px;
    display: inline-block;
    padding: 4px 15px;
    border-radius: 20px;
    text-transform: capitalize;
    border: 1px solid #ebebeb;
}

.comment-section ul li .comment-body .reply-btn a:hover {
    color: #fff !important;
    border-color: #24babc;
    background-color: #24babc;
}

/*--- blog comment section end ---*/
/*------ blog comment box start -----*/
.blog-comment-wrapper h3 {
    color: #252525;
    font-size: 18px;
    line-height: 1;
    font-weight: 500;
    padding-bottom: 15px;
    text-transform: uppercase;
    border-bottom: 1px solid #f0f0f0;
}

.blog-comment-wrapper p {
    margin-bottom: 10px;
    padding-top: 10px;
}

.blog-comment-wrapper .comment-post-box label {
    color: #252525;
    font-weight: 500;
    margin-bottom: 10px;
    display: block;
    text-transform: capitalize;
}

.blog-comment-wrapper .comment-post-box .coment-field {
    background: #f8f8f8;
    border: none;
    color: #555;
    padding: 8px 10px;
    width: 100%;
}

@media only screen and (max-width: 767.98px) {
    .blog-comment-wrapper .comment-post-box .coment-field {
        margin-bottom: 20px;
    }
}

.blog-comment-wrapper .comment-post-box textarea {
    height: 130px;
    margin-bottom: 20px;
    padding: 10px;
    width: 100%;
    background: #f8f8f8;
    border: none;
}

.blog-comment-wrapper .comment-post-box .coment-btn {
    margin-top: 30px;
}

@media only screen and (max-width: 767.98px) {
    .blog-comment-wrapper .comment-post-box .coment-btn {
        margin-top: 10px;
    }
}

/*------- blog comment box end --------*/
.error h1 {
    font-size: 200px;
    font-weight: 900;
    letter-spacing: 1rem;
    line-height: 1.1;
    margin-top: -36px;
    color: #24babc;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .error h1 {
        font-size: 130px;
    }
}

@media only screen and (max-width: 767.98px) {
    .error h1 {
        font-size: 100px;
        margin-top: -16px;
    }
}

.error h2 {
    font-size: 25px;
}

@media only screen and (max-width: 479.98px) {
    .error h2 {
        font-size: 20px;
    }
}

.error .searchform {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 40px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.error .searchform__input {
    border: 1px solid #eeeeee;
    height: 45px;
    width: 100%;
    padding-left: 15px;
}

@media only screen and (max-width: 479.98px) {
    .error .searchform__input {
        width: 85%;
    }
}

.error .searchform__submit {
    border: none;
    background-color: #24babc;
    width: 70px;
    color: #fff;
    cursor: pointer;
}

.error .searchform__submit:hover {
    background-color: #252525;
}

/*-------- footer top area start --------*/
.footer-top {
    padding: 40px 0px;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px),
only screen and (max-width: 767.98px) {
    .footer-widget-item {
        margin-bottom: 40px;
    }
}

.footer-widget-inner {
    padding-top: 68px;
    padding-bottom: 60px;
    border-top: 1px solid #32373d;
    border-bottom: 1px solid #32373d;
}

@media only screen and (min-width: 768px) and (max-width: 991.98px) {
    .footer-widget-inner {
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 767.98px) {
    .footer-widget-inner {
        padding-top: 48px;
        padding-bottom: 2px;
    }
}

.footer-widget-logo {
    padding-bottom: 15px;
}

.footer-widget-title {
    padding-bottom: 27px;
}

@media only screen and (max-width: 767.98px) {
    .footer-widget-title {
        padding-bottom: 20px;
    }
}

.footer-widget-title h5 {
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
}

.footer-widget-body p {
    color: #81898f;
    font-size: 15px;
    line-height: 1.5;
}

.footer-widget-body .contact-info-title {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    padding-top: 20px;
    padding-bottom: 8px;
}

.footer-widget-body li {
    margin-bottom: 10px;
}

.footer-widget-body li a {
    color: #81898f;
    font-size: 15px;
    position: relative;
    text-transform: capitalize;
}

.footer-widget-body li a:before {
    top: 50%;
    left: 0;
    font-size: 16px;
    content: "\f105";
    padding-right: 5px;
    font-family: "FontAwesome";
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.footer-widget-body li a:hover {
    color: #24babc;
    padding-left: 5px;
}

.footer-widget-body li:last-child {
    margin-bottom: 0;
}

.footer-social-link {
    padding-top: 20px;
}

.footer-social-link a {
    color: #81898f;
    font-size: 22px;
    line-height: 1;
    display: inline-block;
    margin-right: 20px;
}

.footer-social-link a:hover {
    color: #24babc;
}

.footer-social-link a:last-child {
    margin-right: 0;
}

/*-------- footer top area end --------*/
.footer-bottom-area {
    background-color: #14181c;
    padding: 20px 0;
}

.copyright-text p {
    font-size: 14px;
    color: #a8a8a8;
}

.copyright-text p a {
    color: #24babc;
}

.copyright-text p a:hover {
    text-decoration: underline;
}